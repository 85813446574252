// @ts-check
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import * as yup from "yup";
import {Grid, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import MUITextField from "@material-ui/core/TextField";
import { useFormikContext } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { Field, globalProps } from "../../../../../components-cross/Field";
import useConfig from "../../../../../hooks/useConfig";

import Wizard from "./Wizard";
import ArtistsButtons from "./StepAlbum.ArtistsButtons";
import { ArtistPrimaryField, ArtistsFields } from "./StepAlbum.ArtistsFields";
import HeaderAlbum from './HeaderAlbum';
import wizardActions from '../../../../../reducers/wizardNewAlbum/wizardActions';

const useStyles = makeStyles((theme) => ({
  preview: {
    display: "flex",
    // justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  titleBlock: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    gap: theme.spacing(2),
  },
  media: {
    width: "100%",
    paddingTop: "90%",
  },
}));

/** @typedef {import('../../../../../reducers/store').StateStore} StateStore */
/** @typedef {import('../../../../../reducers/wizardNewAlbum/wizardStore').NewAlbumTrack} NewAlbumTrack */

export const StepAlbumArtists = () => {
  const config = useConfig();
  const [countries, setCountries] = useState([]);
  const { errors } = useFormikContext();

  /** @type {Array<NewAlbumTrack>} */
  const tracks = useSelector(
    /**
     * @param {StateStore} state
     * @return {Array<NewAlbumTrack>}
     */
    (state) => state.wizardNewAlbumState.tracks
  );
  

  useEffect(() => {
    setCountries(config.countries?.map((country) => country.label));
  }, [config]);

  const { values } = useFormikContext();

  return (
    <Wizard.Step>

      <Grid container spacing={5}>

        <Grid item xs={12}>
          <HeaderAlbum
            album={values}
          />
        </Grid>

        <Grid item xs={12} >
          <div style={{ borderBottom: "1px solid #ddd" }}>
            <Typography variant="h4"  align="center" gutterBottom>
              Artistas de {tracks.length > 1 ? "álbum" : "single"} 
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <ArtistPrimaryField />
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            name="country"
            component={Autocomplete}
            options={countries}
            renderInput={(params) => (
              <MUITextField
                {...params}
                error={!!errors["country"]}
                helperText={errors["country"] || "País del artista principal"}
                label="Pais"
                name="country"
                required
                fullWidth
                {...globalProps}
              />
            )}
          />
        </Grid>

        <ArtistsFields />

        <Grid item xs={12}>
          <ArtistsButtons />
        </Grid>
      </Grid>
    </Wizard.Step>
  );
};

StepAlbumArtists.label = "Album";

StepAlbumArtists.validationSchema = yup.object({
  // title: yup.string().required(),
  // genreSubgenre: yup.mixed().required(),
  country: yup.mixed().required(),
  // photos: yup.object({
  //   cover: yup.mixed().required(),
  // }),
  // upc: yup.string().matches(/^\d{13}$/, "Debé ser un número de 13 cifras"),
  artists: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
    })
  ),
});

export default StepAlbumArtists;
