import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { functions } from "../../firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Divider from "@material-ui/core/Divider";
import PaymentStatusChip from "../reporting/PaymentStatusChip";
import localizerFactory from "../../utils/localizerFactory";
import {
  CircularProgress,
  TableContainer,
  Box,
  Slide,
  Tab,
} from "@material-ui/core";
import { PaymentForm } from "../reporting/payment-form";
import { addNotice, addError } from "../flash-messages";
import AppConfig from "../AppConfig";
import { values, orderBy, keys } from "lodash";
import Chip from "@material-ui/core/Chip";
import AccountContext from "../../contexts/AccountContext";
import DownloadXLSX from "./DownloadXLSX";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
// import WorkingVelo from "./WorkingVelo";

const localMoney = localizerFactory();

const MIN_PAYMENT = 20;

const reqPay = functions.httpsCallable("requestPayment2");

const useStyles = makeStyles((theme) => ({
  root: {
    color: "blue",
    minWidth: "50vw",
    minHeight: "50vh",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  rootMultas: {
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.9),
  },
  tableMultas: {
    marginTop: theme.spacing(0),
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    gap: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  tableHeaderMultas: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
  },
  tableHeaderTitles: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  },
  multasCell: {
    color: "red",
  },
  arrowWay: {
    position: "absolute",
    left: 0,
    top: "65%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    fontSize: theme.spacing(5),
    opacity: 0.7,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  arrow: {
    color: "rgb(204, 170, 102)",
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

/* table head column config */
const colHeads = [
  {
    id: "periodpublished",
    numeric: false,
    disablePadding: true,
    label: "Publicados en",
  },
  {
    id: "periodgenerated",
    numeric: false,
    disablePadding: true,
    label: "Generados en",
  },
  {
    id: "xlsx",
    numeric: false,
    disablePadding: true,
    label: "XLSX",
    onlyOwners: true,
  },
  { id: "total", numeric: true, disablePadding: true, label: "Regalías" },
  { id: "status", numeric: false, disablePadding: true, label: "Estado" },
];

const PaymentTable = ({ data, multas, reload, uid }) => {
  const [selected, setSelected] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [totalMultas, setTotalMultas] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [working, setWorking] = useState(true);
  const [numSelected, setNumSelected] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [startSwipeArrow, setStartSwipeArrow] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState("right");

  const account = useContext(AccountContext);
  const isAccountOwner = account?.imOwner;

  const canDownloadXlsx = isAccountOwner || isAdmin() || isSafeAdmin();

  const classes = useStyles();

  useEffect(() => {
    if (keys(data).length) {
      setWorking(false);
      setTimeout(() => setStartSwipeArrow(true), 2000);
      setTimeout(() => {
        setSwipeDirection("left");
        setStartSwipeArrow(false);
      }, 5000);
    }
  }, [data]);

  useEffect(() => {
    setNumSelected(selected.length);
  }, [selected]);

  useEffect(() => {
    setRowCount(values(data).filter((x) => x.status === "unpaid").length);
  }, [data]);

  useEffect(() => {
    if (multas) {
      // total multas
      const total = multas
        .filter((multa) => multa.status === "unpaid")
        .reduce((t, multa) => t + multa.amount, 0);

      setTotalMultas(total);

      if (selectedAmount === 0) {
        setSelectedAmount(0 - total);
      }
    }
  }, [multas]);

  const requestPayment = async () => {
    try {
      setWorking(true);

      const payments = values(data).filter((x) => selected.includes(x.period));

      const payload = {
        payments,
        multas,
      };

      const result = await reqPay(payload);

      if (result.data === "ok") {
        setSelected([]);
        setSelectedAmount(0);
        setWorking(false);
        reload();

        addNotice({
          text: "Pago solicitado correctamente.",
        });
      } else {
        addError({
          text: "Error en la solicitud, por favor ponte en contacto con nosotros.",
        });
      }

      setWorking(false);
    } catch (e) {
      addError({
        text: "Error en la solicitud, por favor ponte en contacto con nosotros.",
      });
    }
  };

  const handleSelectAllClick = (event) => {
    const unpaid = values(data).filter((x) => x.status === "unpaid");

    if (event.target.checked) {
      setSelected(unpaid.map((n) => n.period));
      setSelectedAmount(unpaid.reduce((t, n) => t + n.total, 0) - totalMultas);
    } else {
      setSelected([]);
      setSelectedAmount(0);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const newSelectedAmount =
      values(data)
        .filter((x) => newSelected.includes(x.period))
        .reduce((t, x) => t + x.total, 0) - totalMultas;

    setSelected(newSelected);
    setSelectedAmount(newSelectedAmount);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const getStatus = (status) => {
    switch (status) {
      case "unpaid":
        return "Pendiente";
      case "requested":
        return "En curso";
      case "paid":
        return "Pagado";
      default:
        return "Desconocido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "unpaid":
        return "red";
      case "paid":
        return "#2ecc71";
      default:
        return "Desconocido";
    }
  };

  const translateReason = (reason) => {
    switch (reason) {
      case "Threshold for artificial streaming fee exceeded":
        return "Umbral de streaming artificial excedido";
      default:
        return "Contactar soporte";
    }
  };

  return (
    <>
      {/* <WorkingVelo working={working} /> */}

      {multas.length > 0 && (
        <Paper className={classes.rootMultas}>
          <div className={classes.tableHeaderMultas}>
            <Typography variant="h6" id="tableTitle" color="textPrimary">
              Multas
            </Typography>

            <Typography variant="subtitle1" color="textPrimary">
              El total de multas se descontará del próximo pago que solicites.
            </Typography>
          </div>

          <Divider />

          <TableContainer className={classes.tableMultasWrapper}>
            <Table className={classes.tableMultas} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Período</TableCell>
                  <TableCell>Multa</TableCell>
                  <TableCell>Infracción</TableCell>
                  <TableCell>Track</TableCell>
                  <TableCell padding="normal" align="right">
                    Estado
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {multas.map((m) => (
                  <TableRow>
                    <TableCell>{m.period}</TableCell>
                    <TableCell>{localMoney.format(m.amount)}</TableCell>
                    <TableCell>{translateReason(m.reason)}</TableCell>
                    <TableCell>{m.trackName}</TableCell>
                    <TableCell padding="normal" align="right">
                      <Chip
                        label={getStatus(m.status)}
                        style={{
                          backgroundColor: getStatusColor(m.status),
                          color: "white",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: ".8em",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <Paper className={classes.root}>
        <Box className={classes.arrowWay}>
          <Slide
            direction={swipeDirection}
            in={startSwipeArrow}
            mountOnEnter
            unmountOnExit
          >
            <SwapHorizontalCircle
              fontSize="inherit"
              className={classes.arrow}
            />
          </Slide>
        </Box>

        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderTitles}>
            <Typography variant="h6" id="tableTitle" color="textPrimary">
              Solicitud de pagos
            </Typography>

            <Typography variant="subtitle1" color="textPrimary">
              Selecciona los períodos que quieres incluir en el pago.{" "}
              {selected && <u>Pago mínimo $20.</u>}
            </Typography>
          </div>

          <Tooltip
            title={`El pago mínimo es $${MIN_PAYMENT}`}
            placement="top-start"
            enterDelay={100}
          >
            <div>
              {working ? (
                <CircularProgress variant="indeterminate" />
              ) : (
                <AppConfig>
                  <PaymentForm
                    requestPayment={requestPayment}
                    working={working}
                    numSelected={selected.length}
                    selectedAmount={selectedAmount}
                    MIN_PAYMENT={MIN_PAYMENT}
                    localMoney={localMoney}
                    aria-label="Payment"
                  ></PaymentForm>
                </AppConfig>
              )}
            </div>
          </Tooltip>
        </div>

        <Divider />

        <TableContainer className={classes.tableWrapper}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {colHeads.map((row) => {
                  if (row.onlyOwners && !canDownloadXlsx) {
                    return null;
                  }

                  return (
                    <TableCell
                      key={row.id}
                      align={
                        row.id === "status" || row.id === "xlsx" || row.numeric
                          ? "right"
                          : "left"
                      }
                      padding="normal"
                      className={row.hide ? classes.hideOnMobile : ""}
                    >
                      {row.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {orderBy(values(data), ["period"], ["desc"])
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isThisSelected = isSelected(n.period);
                  const isPayable = n.status === "unpaid" && n.total > 0;

                  return (
                    <TableRow
                      hover={isPayable}
                      onClick={
                        isPayable
                          ? (event) => handleClick(event, n.period)
                          : null
                      }
                      role="checkbox"
                      aria-checked={isThisSelected}
                      tabIndex={-1}
                      key={n.period}
                      selected={isThisSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isThisSelected} />
                      </TableCell>

                      <TableCell padding="normal" align="left">
                        <Typography noWrap>
                          {n.periodAccountingHuman}
                        </Typography>
                      </TableCell>

                      <TableCell padding="normal" align="left">
                        <Typography noWrap>{n.periodHuman}</Typography>
                      </TableCell>

                      {canDownloadXlsx && (
                        <TableCell padding="normal" align="right">
                          <DownloadXLSX
                            period={n.period}
                            tarifa={account?.tarifa || "TARIFA_USUARIO"}
                            onWorking={setWorking}
                            uid={uid}
                          />
                        </TableCell>
                      )}

                      <TableCell padding="normal" align="right">
                        {localMoney.format(n.total)}
                      </TableCell>

                      <TableCell padding="normal" align="right">
                        <PaymentStatusChip statusKey={n.status} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={values(data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default PaymentTable;
