import { useState } from 'react';
import { Button, ButtonBase, Dialog, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { functions } from "../../../../firebase";


// Get the callable function
const changeUserEmail = functions.httpsCallable('changeEmail');

const ChangeEmail = ({uid, email, reload}) => {
  const [open, setOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ error: '', success: '' });

  const handleOpen = () => {
    setNewEmail('');
    setFeedback({ error: '', success: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setFeedback({ error: '', success: '' });
    
    try {
      const result = await changeUserEmail({ newEmail, uid });
      setFeedback({ error: '', success: result.data.message });
      setOpen(false);
      reload();
    } catch (err) {
      setFeedback({ error: err.message, success: '' });
    }
    setLoading(false);
  };

  return (
    <>
      <Button onClick={handleOpen} color="primary" style={{ textTransform: 'none', padding: 0, flexWrap: 'no-wrap' }}>
        {email}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Email"
            type="email"
            fullWidth
            value={newEmail}
            onChange={handleChange}
          />
          {feedback.error && <p style={{ color: 'red' }}>{feedback.error}</p>}
          {feedback.success && <p style={{ color: 'green' }}>{feedback.success}</p>}
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={loading || !newEmail} color="primary">
            {loading ? 'Updating...' : 'Update Email'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeEmail;
