//@ts-check
import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import DateRender from "../../../../../components-cross/DateRender";

const useStyles = makeStyles((theme) => ({
  headerAlbum: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& .headerAlbum__albumFormat": {
      textTransform: "uppercase",
      fontSize: "1rem",
    },
    "& .headerAlbum__albumTitle": {
      fontSize: "2rem",
      marginTop: 0,
      marginBottom: "0.5rem",
    },
    "& .headerAlbum__details": {
      fontSize: "0.85rem",
    },
  },
}));

const AlbumHeaderDetail = ({ album }) => {
  const classes = useStyles();
  const countTracks = useMemo(() => {
    return album.tracks ? Object.values(album.tracks).length : 0;
  }, [album.tracks]);

  return (
    <div className={classes.headerAlbum}>
      <div className="headerAlbum__albumFormat">{album.format}</div>
      <h2 className="headerAlbum__albumTitle">{album.titleLabel || album.title}</h2>
      <div className="headerAlbum__details">
        <DateRender value={album.release} pattern="yyyy" /> ·{" "}
        {album.genreSubgenre && (
          <span className="headerAlbum__albumGenre">
            {album.genreSubgenre.genre}/{album.genreSubgenre.subgenre}
          </span>
        )}{" "}
        · {countTracks} track{countTracks > 0 && "s"}
      </div>
    </div>
  );
};

export default AlbumHeaderDetail;
