import React, { useCallback, useEffect, useState } from "react";
import { get, debounce } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Field, useFormikContext } from "formik";
import { Autocomplete as AutocompleteFormik } from "formik-material-ui-lab";
import CircularProgress from "@material-ui/core/CircularProgress";

import artistsService from "../services/artistsService";

const useStyle = makeStyles((theme) => ({
  img: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    marginRight: "0.5em",
  },
  avatarEmpty: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    marginRight: "0.5em",
    border: "1px solid #CCC",
    backgroundColor: "#CCC",
    display: "table",
    position: "relative",
    "& svg": {
      width: "20px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  detail: {
    display: "flex",
    flexDirection: "column",
  },
  artistName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
  genres: {
    color: theme.palette.text.hint,
    fontSize: "0.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
}));

const ArtistAvatar = ({ artist }) => {
  const classes = useStyle();

  if (!artist || !artist.images) {
    return <></>;
  }

  return (
    <>
      {artist.images.length > 0 ? (
        <img
          alt={`Avatar ${artist.name}`}
          className={classes.img}
          src={artist.images[artist.images.length - 1].url}
        />
      ) : (
        <div className={classes.avatarEmpty}>
          <PersonIcon />
        </div>
      )}
    </>
  );
};

const Artist = ({ artist }) => {
  const classes = useStyle();

  return (
    <>
      <ArtistAvatar artist={artist} />
      <div className={classes.detail}>
        <div className={classes.artistName}>{artist.label || artist.name}</div>
        <div className={classes.genres}>{artist.genres.join(", ")}</div>
      </div>
    </>
  );
};

const defaultFilter = createFilterOptions();

export const AutocompleteArtists = ({
  onChange,
  label,
  error,
  helperText,
  InputProps,
  required = false,
  isFormikContext = false,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const onChangeQuery = useCallback(
    debounce((value) => {
      setLoading(true);

      // get spotify artists
      artistsService
        .search(value)
        .then((result) => {
          setOptions(result);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }, 500),
    []
  );

  const handleOnChange = (e, spotyArtist, reason) => {
    if (onChange) {
      onChange(e, spotyArtist, reason);
    }
  };

  const handleInputChange = (e, data) => {
    if (onChange) {
      onChange(e, data);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // TODO Is there a better way to resolve this?
  const AutocompleteComp = isFormikContext ? AutocompleteFormik : Autocomplete;

  return (
    <AutocompleteComp
    {...props}
    handleHomeEndKeys
    // clearOnBlur
    freeSolo
    open={open}
    onOpen={() => setOpen(true)}
    onClose={() => setOpen(false)}
    filterOptions={(opts, params) => {
      let filtered = defaultFilter(opts, params);

      return filtered;
    }}
    getOptionSelected={(option, value) => option.name === value.name}
    getOptionLabel={(option) => option.label || option.name || option}
    options={options}
    loading={loading}
    loadingText="Buscando en Spotify..."
    renderOption={(option) => <Artist artist={option} />}
    onChange={handleOnChange}
    onInputChange={handleInputChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        variant="outlined"
        required={required}
        error={error}
        helperText={helperText}
        onChange={(e) => onChangeQuery(e.target.value)}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading && <CircularProgress color="inherit" size={18} />}
              {params.InputProps?.endAdornment}
              {InputProps?.endAdornment}
            </>
          ),
        }}
      />
    )}
    />
  );
};

export const AutocompleteArtistField = ({
  name,
  label,
  InputProps,
  onChange,
  ...props
}) => {
  const { touched, errors, setFieldValue } = useFormikContext();

  const touch = get(touched, name);
  const error = get(errors, name);

  /**
   * @param {object} e
   * @param {object | string} spotyArtist
   * @param {string} reason
   */
  const handleOnChange = (e, spotyArtist, reason) => {
    setFieldValue(name, spotyArtist?.name || spotyArtist || "");
    if (onChange) {
      onChange(spotyArtist);
    }
  };

  return (
    <Field
      component={AutocompleteArtists}
      label={label}
      name={name}
      fullWidth
      required
      error={touch && !!error}
      helperText={touch && error}
      onChange={handleOnChange}
      InputProps={InputProps}
      isFormikContext={true}
      onKeyPress={(e) => {
        if (e.which === 13) {
          e.preventDefault();
        }
      }}
    />
  );
};

export default AutocompleteArtists;
