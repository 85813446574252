import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import {
  saveUserSocialNetworks,
  deleteSocialNetwork,
} from "../../../../../firebase/db";
import SNField from "./SNField";
import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { keys } from "lodash";

import { SocialLinks } from "social-links";

import SocialNetwork from "./SocialNetwork";
import * as routes from "../../../../../constants/routes";
import { useHistory } from "react-router-dom";

const socialLinks = new SocialLinks();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "green",
  },
  newSnBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

const SocialInsufficiencyDialog = ({
  user,
  open,
  handleClose,
  handlePublish,
  socialNetworks,
  nextStep,
}) => {
  const [socialNetworksUpdated, setSocialNetworksUpdated] = useState({});
  const [newLinkValue, setNewLinkValue] = useState("");
  const [newProfileName, setNewProfileName] = useState("");
  const [isValidLink, setIsValidLink] = useState(false);
  const [sanitizedLink, setSanitizedLink] = useState("");

  const history = useHistory();

  useEffect(() => {
    socialNetworks && setSocialNetworksUpdated(socialNetworks);
  }, [socialNetworks]);

  const handleSave = async () => {
    await saveUserSocialNetworks(socialNetworksUpdated);
    history.push(nextStep);
  };

  const clearNewSN = () => {
    setNewProfileName("");
    setSanitizedLink("");
    setIsValidLink(false);
    setNewLinkValue("");
  };

  const addSocialNetwork = (payload) => {
    setSocialNetworksUpdated({ ...socialNetworksUpdated, ...payload });
    clearNewSN();
  };

  const changeField = (e) => {
    const { value } = e.target;

    setNewLinkValue(value);

    const profileName = socialLinks.detectProfile(value);

    if (profileName && profileName !== "mastodon") {
      setNewProfileName(profileName);

      const isValid = socialLinks.isValid(profileName, value);
      setIsValidLink(isValid);

      if (profileName && isValid) {
        const sanitized = socialLinks.sanitize(profileName, value);
        setSanitizedLink(sanitized);
      }
    }
  };

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Añade tus redes sociales</DialogTitle>

      <Divider />

      <DialogContent style={{ padding: 25 }}>
        <Typography variant="body1">
          👉 <strong>PRO TIP:</strong> Tus redes sociales son{" "}
          <strong>muy importantes</strong> para nuestro equipo{" "}
          <strong>anti-fraude</strong>. Sirven para verificar tu identidad y
          proteger la música que subes.{" "}
          <u>Cuantas más nos des, más rápido procesamos tu subida.</u>
        </Typography>
      </DialogContent>

      <Divider />

      <DialogContent>
        {Object.keys(socialNetworksUpdated).map((key) => (
          <SNField
            key={key}
            sn={key}
            value={socialNetworksUpdated[key]}
            hanldeDelete={deleteSocialNetwork}
          />
        ))}
      </DialogContent>

      <Divider />

      <DialogContent>
        <Box className={classes.newSnBox}>
          <SocialNetwork name={newProfileName} />

          <TextField
            autoFocus
            margin="dense"
            fullWidth
            variant="standard"
            helperText={
              isValidLink
                ? sanitizedLink
                : "URL perfil, ej. https://www.facebook.com/yo"
            }
            onChange={changeField}
            value={newLinkValue}
          />

          <Button
            variant="contained"
            disabled={!isValidLink}
            onClick={() =>
              addSocialNetwork({ [newProfileName]: sanitizedLink })
            }
          >
            Añadir
          </Button>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={keys(socialNetworksUpdated).length < 2}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SocialInsufficiencyDialog;
