import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const SocialNetwork = ({ name }) => {
  if (!name) return null;

  switch (name) {
    case "facebook":
      return <FacebookIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "twitter":
    case "x":
      return (
        <SvgIcon>
          <path d="m18.9,1.153h3.682l-8.042,9.189,9.46,12.506h-7.405l-5.804-7.583-6.634,7.583H.469l8.6-9.831L0,1.153h7.593l5.241,6.931,6.065-6.931Zm-1.293,19.494h2.039L6.482,3.239h-2.19l13.314,17.408Z" />
        </SvgIcon>
      );
    case "youtube":
      return <YouTubeIcon />;
    case "tiktok":
      return (
        <SvgIcon>
          <path d="M22.465,9.866c-2.139,0-4.122-0.684-5.74-1.846v8.385c0,4.188-3.407,7.594-7.594,7.594c-1.618,0-3.119-0.51-4.352-1.376  c-1.958-1.375-3.242-3.649-3.242-6.218c0-4.188,3.407-7.595,7.595-7.595c0.348,0,0.688,0.029,1.023,0.074v0.977v3.235  c-0.324-0.101-0.666-0.16-1.023-0.16c-1.912,0-3.468,1.556-3.468,3.469c0,1.332,0.756,2.489,1.86,3.07  c0.481,0.253,1.028,0.398,1.609,0.398c1.868,0,3.392-1.486,3.462-3.338L12.598,0h4.126c0,0.358,0.035,0.707,0.097,1.047  c0.291,1.572,1.224,2.921,2.517,3.764c0.9,0.587,1.974,0.93,3.126,0.93V9.866z" />
        </SvgIcon>
      );
    case "soundcloud":
      return (
        <SvgIcon>
          <path
            id="Cloud_1_"
            d="M10.069,16.995L9.931,14.43l0.138-6.345  c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,6.345l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405  C10.254,17.4,10.069,17.219,10.069,16.995z M8.414,16.995L8.276,14.43l0.138-4.725c0-0.224,0.185-0.405,0.414-0.405  c0.228,0,0.414,0.181,0.414,0.405l0.138,4.725l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405C8.599,17.4,8.414,17.219,8.414,16.995z   M6.759,16.995L6.621,14.43l0.138-5.265c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,5.265l-0.138,2.565  c0,0.224-0.185,0.405-0.414,0.405C6.944,17.4,6.759,17.219,6.759,16.995z M5.103,16.995L4.966,14.43l0.138-4.185  c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,4.185l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405  C5.289,17.4,5.103,17.219,5.103,16.995z M3.448,16.995L3.31,14.43l0.138-2.025C3.448,12.181,3.634,12,3.862,12  s0.414,0.181,0.414,0.405l0.138,2.025l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405S3.448,17.219,3.448,16.995z M1.793,16.995  L1.655,14.43l0.138-2.565c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,2.565l-0.138,2.565  c0,0.224-0.185,0.405-0.414,0.405C1.978,17.4,1.793,17.219,1.793,16.995z M0.138,15.915L0,14.43l0.138-1.485  c0-0.224,0.185-0.405,0.414-0.405s0.414,0.181,0.414,0.405l0.138,1.485l-0.138,1.485c0,0.224-0.185,0.405-0.414,0.405  S0.138,16.139,0.138,15.915z M19.515,11.131c0.365-0.136,0.761-0.211,1.175-0.211c1.828,0,3.31,1.451,3.31,3.24  s-1.482,3.24-3.31,3.24h-8.275c-0.457,0-0.828-0.364-0.828-0.811V7.95c0-1.35,2.483-1.35,2.483-1.35  C16.814,6.6,19.09,8.562,19.515,11.131z"
          />
        </SvgIcon>
      );
    default:
      return null;
  }
};

export default SocialNetwork;
