import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const styles = (theme) => ({
  box: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    color: "black",
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  subheading: {
    margin: 0,
    marginBottom: "1.5rem",
  },
});

const StepHeadings = ({ classes, title }) => (
  <Box className={classes.box}>
    <Typography
      variant="h4"
      className={classes.heading}
      align="center"
      gutterBottom
    >
      {title}
    </Typography>
  </Box>
);

export default withStyles(styles)(StepHeadings);
