import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import * as routes from "../../../../../constants/routes";
import { trackCloseAction } from "../../../../../reducers/albumActions";
import { trackNextAction } from "../../../../../reducers/album/actions/trackNext";
import { trackPrevAction } from "../../../../../reducers/album/actions/trackPrev";
import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";
import useTrackErrors from "../../../../../reducers/album/hooks/useTrackErrors";
import useAlbumOpened from "../../../../../reducers/album/hooks/useAlbumOpened";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { ArrowLeft, ArrowRight, ExitToApp } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  trackNav: {
    maxWidth: 1100,
    margin: "0 auto",
    backgroundColor: "transparent",
    // borderBottom: "1px solid #ddd",
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  background: {
    backgroundColor: "rgb(0, 0, 0, 0.1)",
    // backgroundColor: "rgba(204, 170, 102, 0.2)",

    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const TrackNav = () => {
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFirst, setIsFirst] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const album = useAlbumOpened();
  const track = useTrackOpened();
  const { hasErrors } = useTrackErrors();

  useEffect(() => {
    if (!album || !track) {
      return;
    }
    const keys = Object.keys(album.tracks);
    const len = keys.length;
    const _isFirst = track.order === 1;
    const _isLast = track.order === len;

    setIsFirst(_isFirst);
    setIsLast(_isLast);
  }, [album, track]);

  const labelBack = useMemo(() => {
    return location.state?.fromWizard ? "ir al album" : "Volver";
  }, [location.state]);

  const handleOnPrev = () => {
    dispatch(trackPrevAction());
  };

  const handleOnNext = () => {
    dispatch(trackNextAction());
  };

  const handleOnBack = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(trackCloseAction());

    if (location.state?.fromAlbumEditor) {
      history.push(routes.ALBUM_EDIT.replace(":upc", album.upc)); // go back to editor
    } else {
      history.push(routes.ALBUM_VIEW.replace(":upc", album.upc)); // go back to preview
    }
  };

  return (
    <>
      <div className={classes.background}>
        <div className={classes.trackNav}>
          <div className="trackNav__leftSection">
            <Typography variant="subtitle1">EDITOR DE TRACKS</Typography>
          </div>

          <div className="trackNav__midSection">
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
              size="small"
            >
              <Button
                disabled={isFirst || hasErrors}
                color="primary"
                onClick={handleOnPrev}
                startIcon={<ArrowLeft />}
              >
                Anterior
              </Button>
              <Button
                disabled={isLast || hasErrors}
                onClick={handleOnNext}
                endIcon={<ArrowRight />}
              >
                Siguiente
              </Button>
            </ButtonGroup>
          </div>

          <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
            <span style={{ color: "gray" }}>
              Guardado 
            </span>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOnBack}
              startIcon={<ExitToApp />}
            >
              Salir
            </Button>
          </div>
        </div>
      </div>
      {hasErrors && (
        <Alert severity="error">Corrige los errores e inténtalo otra vez</Alert>
      )}
    </>
  );
};

export default TrackNav;
