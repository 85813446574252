export const SIGN_IN = "/";
export const SIGN_UP = "/signup";
export const VERIFY = "/verify";
export const VERIFY_PHONE = "/verify-phone";
export const REPORTING = "/reporting";
export const PAYMENTS = "/payments";
export const ROYALTIES = "/royalties";
export const REPORTING_ADMIN = "/reporting/:uid";
export const TRENDS = "/trends";
export const ALBUMS = "/albums";
export const ALBUM_ADMIN = "/admin/:email";
// export const ALBUM_ADMIN_SEARCH = "/albums/:search";
export const ALBUM_VIEW = "/albums/:upc/preview";
export const ALBUM_PREVIEW = "/preview/:upc";
export const ALBUM_VIEW_PUBLISHLEGAL = "/albums/:upc/preview/legal";
export const ALBUM_EDIT = "/albums/:upc/edit";
export const ALBUM_EDIT_TRACKS = "/albums/:upc/edit/tracks";
export const ALBUM_NOTEXISTS = "/album/no-exists";
export const UPLOAD = "/albums/new";
export const UPLOADED = "/uploaded";
export const EDITED = "/edited";
export const ACCOUNT = "/account";
export const STATS = "/stats";
export const LEGAL_TERMS = "/terminos-legales";
export const ACUERDO_AUTOFACTURACION = "/acuerdo-autofacturacion";
export const PASSWORD_FORGET = "/pw-forget";
export const CHECKOUT = "/checkout";
export const HISTORY = "/history";
export const MANAGE_USERS = "/manage-users";
export const MANAGE_ACCOUNTS = "/manage-accounts";
export const MANAGE_ACCOUNT = "/manage-accounts/:accountId";
export const MANAGE_ROYALTIES = "/manage-royalties";
export const MANAGE_ARTISTS = "/manage-artists";
export const BLACKLIST = "/blacklist";
