import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { keys } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FraudChart = ({ data }) => {
  const [totPages, setTotPages] = useState(0);

  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(1);

  const [chartData, setChartData] = useState(null);
  const [tooltips, setTooltips] = useState(null);

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriodWithLabels, fraudulentByPeriodInSpoty } = data;

      const dataset = {
        label: "Spotify",
        data: fraudulentByPeriodInSpoty.map((row) => row.cantidad),
        backgroundColor: "rgba(255, 0, 0, 0.5)",
      };

      const chartData = {
        labels: fraudulentByPeriodInSpoty.map((row) => row.periodHuman),
        type: "bar",
        datasets: [dataset],
      };

      setChartData(chartData);

      console.log("chartData", chartData);

      // tooltips
      const _tooltips = fraudulentByPeriodInSpoty.map(
        (row) => row.totalStreams
      );
      setTooltips(_tooltips);
    }
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function ([{ dataIndex }]) {
            return "Fraudulentas:";
          },
          // afterTitle: function () {
          //   return "Fraudulentas:";
          // },
          // label: function ({ dataIndex }) {
          //   const item = tooltips[dataIndex];
          //   return `Escuchas legítimas: ${item}`;
          // },
          // footer: function ({ dataIndex }) {
          //   const item = Math.round(tooltips[dataIndex]);
          //   return `Legítimas del período: ${item}`;
          // },
          footer: function ([{ dataIndex }]) {
            const item = Math.round(tooltips[dataIndex]);
            return `Legítimas del período: ${item}`;
          },
        },
      },
    },
  };

  const classes = useStyles();

  if (!chartData) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        Reproducciones fraudulentas detectadas por las plataformas
      </Typography>

      <Bar data={chartData} options={options} />
    </div>
  );
};

export default FraudChart;
