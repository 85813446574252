// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import SwitchWithLabel from "../../../../../components-cross/SwitchWithLabel";
import { Field, globalProps } from "../../../../../components-cross/Field";
import AutocompleteGenreField from "../../../../../components-cross/AutocompleteGenreField";
import useConfig from "../../../../../hooks/useConfig";

import Wizard from "./Wizard";
import StepAlbumCover2 from "./StepAlbum.Cover2";
import VersionField from "./StepAlbum.VersionField";
import UpcField from "./StepAlbum.UpcField";
import { setArtistRolesAvailablesAction } from "../../../../../reducers/wizardNewAlbum/actions/setArtistRolesAvailables";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(0),
    },
  },
  titleBlock: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  advancedFields: {
    display: "flex",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
      flexDirection: "row",
    },
  },
}));

export const StepAlbum = (props) => {
  const config = useConfig();
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const { errors } = useFormikContext();

  const classes = useStyles();

  useEffect(() => {
    setCountries(config.countries?.map((country) => country.label));
  }, [config]);

  const onChangeGenre = (value) => {
    if (value) {
      dispatch(setArtistRolesAvailablesAction(value.genre, value.subgenre));
    }
  };

  return (
    <Wizard.Step>
      <Wizard.Heading
        title="Portada y título"
      />

      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <StepAlbumCover2 />
        </Grid>

        <Grid item xs={12} md={8}>
          <Box className={classes.container}>
            <Box className={classes.titleBlock}>
              <Field
                component={TextField}
                label="Título"
                name="title"
                required
                fullWidth
              />
              <AutocompleteGenreField
                label="Género"
                name="genreSubgenre"
                onChange={onChangeGenre}
                {...globalProps}
              />
              <Field
                component={SwitchWithLabel}
                type="checkbox"
                label="En vivo"
                name="live"
              />
            </Box>

            <Box className={classes.advancedFields}>
              <UpcField />
              <VersionField />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Wizard.Step>
  );
};

StepAlbum.label = "Album";

StepAlbum.validationSchema = yup.object({
  title: yup.string().required(),
  genreSubgenre: yup.mixed().required(),
  // country: yup.mixed().required(),
  photos: yup.object({
    cover: yup.mixed().required(),
  }),
  upc: yup
    .string()
    .matches(
      /^\d{13}$/,
      "UPC inválido. Elimina el campo y te asignamos uno gratis."
    ),
});

export default StepAlbum;
