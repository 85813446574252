import React, { useState, useEffect, useMemo, useContext } from "react";
import withAuthorization from "../../components/withAuthorization";
import { auth, fbroot } from "../../firebase";
import ScrollToTop from "../../components/ScrollToTop";
import Album from "./components/Album";
import AlbumSearch from "./components/AlbumSearch";
import Typography from "@material-ui/core/Typography";
import { Pagination } from "@material-ui/lab";
import { UPLOAD } from "../../constants/routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import isAdmin from "../../utils/isAdmin";
import isSafeAdmin from "../../utils/isSafeAdmin";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { orderBy, keys, values } from "lodash";
import Filters from "./components/filters";
import Sorting from "./components/sorting";
import useConfig from "../../hooks/useConfig";
import { useHistory, useLocation } from "react-router-dom";
import UploaderInfo from "./components/UploaderInfo";
import isLocalDev from "../../utils/isLocalDev";
import AccountContext from "../../contexts/AccountContext";
import AuthUserContext from "../../components/AuthUserContext";

const db = fbroot.database();

const canUseSearch = () => {
  return isAdmin() || isSafeAdmin();
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    maxWidth: "90vw",
  },

  layout: {
    display: "flex",
  },

  uploaderColumn: {
    width: "25%",
    minWidth: 200,
    marginRight: theme.spacing(4),
  },

  heading: {
    marginBottom: theme.spacing(3),
    zIndex: 2,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
  },
  filters: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  tusAlbumsTitle: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  loadingNotice: {
    width: "100%",
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "yellow",
    grow: 1,
  },
  paginationTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  paginationBottom: {
    margin: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    margin: theme.spacing(10),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noAlbums: {
    marginTop: theme.spacing(5),
  },
}));

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const AlbumsPage = () => {
  const searchParams = useQuery();

  const [loading, setLoading] = useState(false);
  const [albums, setAlbums] = useState({});
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [filter, setFilter] = useState("all");
  const [filterCount, setFilterCount] = useState({});
  const [sort, setSort] = useState("date");
  const [fetchType, setFetchType] = useState("default");
  const [fetchAgain, setFetchWhen] = useState(0);
  const [viewer, setViewer] = useState("authUser");

  const [showPagination, setShowPagination] = useState(true);
  const [numItemsOffset, setNumItemsOffset] = useState(0);
  const [numTotalSet, setNumTotalSet] = useState(0);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  const [uploader, setUploader] = useState("");

  const classes = useStyles();
  const config = useConfig();
  const history = useHistory();

  const NUM_ALBUMS = config?.admin_show_num_albums || 200;
  const PAGE_SIZE = isLocalDev() ? 12 : NUM_ALBUMS;

  const account = useContext(AccountContext);
  const user = useContext(AuthUserContext);

  // finish loading after 5 seconds
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  // update query from URL
  useEffect(() => {
    const q = searchParams.get("q");
    if (q) {
      setQuery(q);
      reFetch();
    }
  }, [searchParams]);

  // who is viewing?
  useEffect(() => {
    // fetch albums
    // if (isAdmin() || isSafeAdmin()) {
    //   setViewer("admin");
    //   fetchForAdmin();
    // } else
    if (keys(account?.members || {}).includes(user.uid)) {
      setViewer("member");
      fetchForMember();
    } else {
      setViewer("authUser");

      fetchForCurrentUser();
    }

    return () => {
      db.ref("catalog").off();
    };
  }, [user, account]);

  // fetch albums
  useEffect(() => {
    searchAlbums();
  }, [fetchAgain]);

  // FILTER COUNT update
  useEffect(() => {
    const updateFilterCount = () => {
      const newFilterCount = values(albums).reduce(
        (totals, album) => {
          const category = getStatusCategory(album.status);

          if (!totals[category]) totals[category] = 0;

          totals[category] += 1;
          totals.all += 1;
          return totals;
        },
        { all: 0 }
      );

      setFilterCount(newFilterCount);
    };

    updateFilterCount();
  }, [albums]);

  // PAGE CHANGE EFFECT
  useEffect(() => {
    setNumItemsOffset(page * PAGE_SIZE - PAGE_SIZE);
  }, [page]);

  // PAGINATION RESET
  useEffect(() => {
    const filteredAlbums = keys(albums).filter(filterAlbum);
    setNumTotalSet(filteredAlbums.length);
    setPage(1);
  }, [albums, filter]);

  useEffect(() => {
    const weDontNeedNoPagination = numTotalSet < PAGE_SIZE;

    if (weDontNeedNoPagination) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [numTotalSet]);

  /**
   * API --------------------------------------------------------------------------------
   */

  // add/change album to state
  const toState = (snap) => {
    console.log(snap.key);
    setAlbums((prev) => {
      return {
        ...prev,
        [snap.key]: snap.val(),
      };
    });

    setLoading(false);
    setMessage("");
  };

  const reFetch = () => {
    setFetchWhen(fetchAgain + 1);
  };

  const searchAlbums = () => {
    let field, value;

    setLoading(true);

    // stop listening to previous search
    db.ref("catalog").off();

    // clear albums
    setAlbums({});

    // ---------------------------- PARSE QUERY ----------------------------

    switch (true) {
      // email search
      case query.includes("@"):
        field = "subaccount_user";
        value = query;
        break;

      // UPC search
      case /^\d{13}$/.test(query):
        field = "upc";
        value = query;
        break;

      // figure out field and value
      case query.includes(":"):
        [field, value] = query.split(":").map((x) => x.trim());
        break;

      // isrc search
      case /^[A-Z]{2}\d{10}$/.test(query):
        field = "isrc";
        value = query;
        break;
    }

    // ---------------------------- CONVERSIONS ----------------------------

    if (field === "email") {
      field = "subaccount_user";
    }

    // ---------------------------- SET UPLOADER ----------------------------
    // search by uploader? save to state so we can show uploader info

    if (field === "subaccount_user") {
      setUploader(value);
    } else {
      setUploader("");
    }

    // ---------------------------- SEARCH ----------------------------

    if (field && value) {
      if (field === "isrc") {
        db.ref("isrcToUpc")
          .child(value)
          .once("value", (snap) => {
            if (snap.exists()) {
              const upc = snap.val();
              db.ref("catalog")
                .child(upc)
                .once("value", (snap) => {
                  if (snap.exists()) {
                    toState(snap);
                  } else {
                    setMessage("No se encontraron resultados");
                  }
                });
            }
          });
      } else {
        // child added
        db.ref("catalog")
          .orderByChild(field)
          .equalTo(value)
          .on("child_added", toState);

        // child changed
        db.ref("catalog")
          .orderByChild(field)
          .equalTo(value)
          .on("child_changed", toState);

        // if searching by subaccount_user (email), also search by subaccount
        // for albums that are in copyright infringement
        if (field === "subaccount_user") {
          // child added
          db.ref("catalog")
            .orderByChild("subaccount")
            .equalTo(value)
            .on("child_added", toState);

          // child changed
          db.ref("catalog")
            .orderByChild("subaccount")
            .equalTo(value)
            .on("child_changed", toState);
        }
      }
    } else {
      setMessage("No se encontraron resultados");
    }
  };

  const fetchForCurrentUser = () => {
    setQuery(`${user.email}`);
    reFetch();
  };

  const fetchForMember = () => {
    setQuery(account.owner_email);
    reFetch();
  };

  const fetchForAdmin = () => {
    console.log("fetchForAdmin");

    // child added
    db.ref("catalog")
      .orderByChild("timestamps/ingested")
      .limitToLast(NUM_ALBUMS)
      .on("child_added", toState);

    // child added
    // db.ref("catalog")
    //   .orderByChild("timestamps/ingested")
    //   .limitToLast(NUM_ALBUMS)
    //   .on("child_changed", toState);
  };

  const clearSearch = () => {
    setAlbums({});
    setQuery("");
    setFetchType("default");
    setUploader("");
    setMessage("");
    history.push({
      pathname: "/albums",
    });
  };

  const getStatusCategory = (status) =>
    config.statuses[status]?.category || "other";

  const filterAlbum = (album) => {
    const { status } = album;
    if (!albums || !status) return false;

    const category = getStatusCategory(status);
    return filter === "all" || category === filter;
  };

  const sortAlbum = (album) => {
    const { title, artists, timestamps } = album;

    if (sort === "date") {
      return timestamps?.uploaded || timestamps?.ingested || 0;
    }

    if (sort === "title") {
      return title.toUpperCase();
    }

    if (sort === "artist") {
      const artista = keys(artists)
        .map((aid) => artists[aid])
        .find((artist) => artist.country !== "");
      return artista.name.toUpperCase();
    }
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = () => {
    setFetchType("search");
    reFetch();

    history.push({
      pathname: "/albums",
      search: `?q=${query}`,
    });
  };

  const handleFilter = (choice) => {
    setFilter(choice);
  };

  const handleSort = (choice) => {
    setSort(choice);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.root}>
      <ScrollToTop />

      {/* Search and tools */}
      <div className={classes.heading}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {canUseSearch() ? (
              <AlbumSearch
                handleChange={handleChange}
                handleSearch={handleSearch}
                clearSearch={clearSearch}
                q={query}
              />
            ) : (
              <Typography className={classes.tusAlbumsTitle} variant="h4">
                {keys(albums).length || 0} ALBUMS
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box className={classes.filters}>
              <Filters
                selected={filter}
                filterCount={filterCount}
                onSelect={handleFilter}
              />

              <Sorting selected={sort} onSelect={handleSort} />
            </Box>
          </Grid>
        </Grid>
      </div>

      {/* Albums / search results */}

      {/* Search information */}

      <Box className={classes.layout}>
        {canUseSearch() && message && !loading && (
          <Grid item xs={12}>
            <Typography>{message}</Typography>
          </Grid>
        )}

        {canUseSearch() && uploader && (
          <Box className={classes.uploaderColumn}>
            <UploaderInfo email={uploader} />
          </Box>
        )}

        <Grid container spacing={4}>
          {orderBy(
            keys(albums).map((upc) => albums[upc]),
            sortAlbum,
            sort === "date" ? "desc" : "asc"
          )
            .filter(filterAlbum)
            .slice(numItemsOffset, numItemsOffset + PAGE_SIZE)
            .map((album) => {
              return (
                <Grid item key={`g${album.upc}`} xs={12} sm={6} md={4} lg={3}>
                  <Album key={`a${album.upc}`} album={album} />
                </Grid>
              );
            })}
        </Grid>
      </Box>

      <Box>
        {loading ? (
          <Box className={classes.loading}>
            <CircularProgress
              variant="indeterminate"
              className={classes.loading}
            />
          </Box>
        ) : (
          keys(albums).length === 0 &&
          !canUseSearch() && (
            <Typography className={classes.noAlbums} variant="body1">
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(UPLOAD)}
                size="large"
              >
                Comienza a subir tu música
              </Button>
            </Typography>
          )
        )}
      </Box>

      {showPagination && (
        <Box className={classes.paginationBottom}>
          <Pagination
            count={Math.ceil(numTotalSet / PAGE_SIZE)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </div>
  );
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(AlbumsPage);
