import { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import packageData from "../../package.json";
import AccountContext from "../contexts/AccountContext";

const Footer = () => {
  const account = useContext(AccountContext);

  const defaults = {
    navbarBackground: "rgba(20, 20, 20,1)",
    navbarText: "rgb(100, 100, 100) !important",
  };

  const { navbarBackground, navbarText } = account?.colors || defaults;

  const classes = makeStyles((theme) => ({
    footer: {
      background: navbarBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      fontSize: ".6rem",
      zIndex: 1200,
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    inner: {
      color: navbarText,
    },
  }))();

  return (
    <footer className={classes.footer}>
      <Typography className={classes.inner}>
        &copy; 2018-{new Date().getFullYear()} Musik.digital
      </Typography>
      <Typography className={classes.inner}>
        App version {packageData.version}
      </Typography>
    </footer>
  );
};

export default Footer;
