import { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { keys } from "lodash";
import Logo from "./Logo"; // Adjust the import path as necessary
import { useHistory } from "react-router-dom";
import ExpandMore from "@material-ui/icons/ExpandMore";

const AccountMenu = ({ user, account, userAccount }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const goHome = () => {
    history.push("/");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccountSwitch = (selectedAccount) => {
    if (selectedAccount.id === account?.id) {
      handleMenuClose();
      return;
    }

    const currentUrl = new URL(window.location.href);
    const currentPath = currentUrl.pathname.split("/")[1];
    const newSubdomain = selectedAccount.subdomain || "www";

    const newUrl = `${currentUrl.protocol}//${newSubdomain}.${currentUrl.host
      .split(".")
      .slice(1)
      .join(".")}/${currentPath}`;
    window.location.href = newUrl;
  };

  const _accounts = [{ ...userAccount, role: "user", id: userAccount.id }];

  if (user.accountOwnerships) {
    keys(user.accountOwnerships).forEach((key) => {
      _accounts.push({
        ...user.accountOwnerships[key],
        role: "owner",
        id: key,
      });
    });
  }

  if (user.accountMemberships) {
    keys(user.accountMemberships).forEach((key) => {
      _accounts.push({
        ...user.accountMemberships[key],
        role: "member",
        id: key,
      });
    });
  }

  const accounts = Array.from(
    new Map(_accounts.map((acc) => [acc.id, acc])).values()
  );

  return (
    <>
      <span
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <Logo
          siteName={account.nameDisplay || account.name}
          onClick={accounts.length > 1 ? handleMenuOpen : goHome}
        />

        {accounts.length > 1 && (
          <ExpandMore
            style={{ marginLeft: "8px", cursor: "pointer", fontSize: "20px" }} // Adjust font size
            onClick={handleMenuOpen}
          />
        )}
      </span>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {accounts.map((acc) => (
          <MenuItem key={acc.id} onClick={() => handleAccountSwitch(acc)}>
            {acc.nameDisplay || acc.name} ({acc.role})
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountMenu;
