import React from "react";
import { useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconDeleteConfirmButton from "../../../../../components-cross/IconDeleteConfirmButton";
import useEditableField from "../hooks/useEditableField";
import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";
import {TextField} from '@material-ui/core';

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{label: string, name: string, type: FieldTypes, level: FieldLevelType, required?:boolean}} props
 */
const EditableComposer = (props) => {
  const pathArtist = props.name;
  const pathArtistName = pathArtist + "/name";
  const dispatch = useDispatch();
  const track = useTrackOpened();

  const {
    upc,
    path,
    label,
    value,
    setValue,
    canDelete,
    deleteField,
    required,
    error,
  } = useEditableField({
    ...props,
    name: pathArtistName,
    debounceTimeToSave: 1000,
  });

  const handleOnDelete = (e) => {
    deleteField();
  };

  const handleOnChnage = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      name={path}
      label={label}
      value={value}
      onChange={handleOnChnage}
      variant="outlined"
      fullWidth
      required={required}
      error={!!error}
      helperText={ error ? error : "IMPORTANTE: Utilizar nombre legal completo"}
      InputProps={
      canDelete
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconDeleteConfirmButton onClick={handleOnDelete} />
              </InputAdornment>
            ),
          }
        : null
      }
    />
  );
};

export default EditableComposer;
