import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { firebase } from "../firebase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import upperCase from "lodash/upperCase";
import * as routes from "../constants/routes";
import isAdmin from "../utils/isAdmin";
import isSafeAdmin from "../utils/isSafeAdmin";
import AccountContext from "../contexts/AccountContext";

const useStyles = makeStyles((theme) => ({
  userBox: {
    padding: theme.spacing(5),
    minWidth: 300,
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    width: 90,
    height: 90,
    marginBottom: theme.spacing(1),
  },
  avatarNoPhoto: {
    width: 90,
    height: 90,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#fff",
    fontWeight: 900,
    backgroundColor: theme.palette.secondary.main,
    fontSize: "3rem",
  },
  menuButton: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1.5),
  },
  email: {
    fontSize: ".85rem",
  },
}));

const UserMenu = ({ user, account }) => {
  const [open, setOpen] = useState(false);
  const [helpSiteDomain, setHelpSiteDomain] = useState("");

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (account?.helpSiteDomain) {
      setHelpSiteDomain(account.helpSiteDomain);
    }
  }, [account]);

  if (!user) return null;

  const { email, displayName, photoURL } = user;

  const handleNavigate = (event) => {
    history.push(event.currentTarget.getAttribute("name"));
    setOpen(false);
  };

  const goToHelpSite = () => {
    window.open(`https://${helpSiteDomain}`);
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="Menu"
        onClick={() => setOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={open} onClose={() => setOpen(false)}>
        <List component="nav" className={classes.list}>
          <ListItem
            button
            name={routes.ACCOUNT}
            className={classes.userBox}
            onClick={handleNavigate}
          >
            {photoURL ? (
              <Avatar src={photoURL} className={classes.avatar} />
            ) : (
              <Avatar className={classes.avatarNoPhoto}>
                {upperCase(
                  displayName ? displayName.charAt(0) : email.charAt(0)
                )}
              </Avatar>
            )}
            <Typography variant="h6">{displayName || email}</Typography>
            <Typography className={classes.email}>{email}</Typography>
          </ListItem>

          <Divider />

          <ListItem button name={routes.ALBUMS} onClick={handleNavigate}>
            <ListItemIcon>
              <Icon>apps</Icon>
            </ListItemIcon>
            <ListItemText primary="Música" />
          </ListItem>

          <ListItem button name={routes.UPLOAD} onClick={handleNavigate}>
            <ListItemIcon>
              <Icon>publish</Icon>
            </ListItemIcon>
            <ListItemText primary="Subir música" />
          </ListItem>

          <ListItem button name={routes.ROYALTIES} onClick={handleNavigate}>
            <ListItemIcon>
              <Icon>attach_money</Icon>
            </ListItemIcon>
            <ListItemText primary="Ingresos" />
          </ListItem>

          <ListItem button name={routes.ACCOUNT} onClick={handleNavigate}>
            <ListItemIcon>
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText primary="Tu cuenta" />
          </ListItem>

          {helpSiteDomain && (
            <ListItem button onClick={goToHelpSite}>
              <ListItemIcon>
                <Icon>help</Icon>
              </ListItemIcon>
              <ListItemText primary="Ayuda" />
            </ListItem>
          )}

          {(isAdmin() || isSafeAdmin()) && (
            <>
              <Divider />
              <ListItem
                button
                name={routes.MANAGE_USERS}
                onClick={handleNavigate}
              >
                <ListItemIcon>
                  <Icon>lock</Icon>
                </ListItemIcon>
                <ListItemText primary="Admin users" />
              </ListItem>
              <ListItem
                button
                name={routes.MANAGE_ACCOUNTS}
                onClick={handleNavigate}
              >
                <ListItemIcon>
                  <Icon>business</Icon>
                </ListItemIcon>
                <ListItemText primary="Admin accounts" />
              </ListItem>
              <ListItem
                button
                name={routes.MANAGE_ROYALTIES}
                onClick={handleNavigate}
              >
                <ListItemIcon>
                  <Icon>attach_money</Icon>
                </ListItemIcon>
                <ListItemText primary="Admin ingresos" />
              </ListItem>
              <ListItem
                button
                name={routes.MANAGE_ARTISTS}
                onClick={handleNavigate}
              >
                <ListItemIcon>
                  <Icon>color_lens</Icon>
                </ListItemIcon>
                <ListItemText primary="Admin artists" />
              </ListItem>

              <ListItem button name={routes.BLACKLIST} onClick={handleNavigate}>
                <ListItemIcon>
                  <Icon>block</Icon>
                </ListItemIcon>
                <ListItemText primary="Blacklist" />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default UserMenu;
