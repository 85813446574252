// @ts-check
import React, { useEffect } from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import * as routes from "../../../../constants/routes";

import useEditor from "../Edit/useEditor";

import ToolbarAlbumPreview from "./components/ToolbarAlbumPreview";
import FooterAlbum from "./components/FooterAlbum";
import HeaderAlbum from "./components/HeaderAlbum";
import TracksList from "./components/TracksList";
import StepLegal from "./components/StepLegal";
import PreviewInfoDialog from "./components/PreviewInfoDialog";

/** @typedef {import("../../../../business-objects/user").User} User */

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(7),
    // marginBottom: theme.spacing(5),
  },
  albumContainer: {
    margin: "auto",
  },
}));

const AlbumPreview = (props) => {
  const location = useLocation();

  const upc = props.match.params.upc;
  const { loading, album } = useEditor(upc); // uso temporal ver si es necesario un usePreview
  const classes = useStyles();
  let { path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!album || loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={path}>
        <Box className={classes.root}>
          <div className={classes.albumContainer}>
            <HeaderAlbum
              album={album}
              toolbar={<ToolbarAlbumPreview album={album} />}
            />

            <TracksList album={album} />

            <FooterAlbum album={album} />
          </div>
        </Box>
        {location.state?.fromWizard && (
          <PreviewInfoDialog genre={album.genre} format={album.format} />
        )}
      </Route>
      <Route path={routes.ALBUM_VIEW_PUBLISHLEGAL}>
        <StepLegal album={album} />
      </Route>
    </Switch>
  );
};

export default AlbumPreview;
