import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, Button, ButtonGroup } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    borderRadius: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      borderRadius: theme.spacing(0),
    },
  },
  chartBox: {
    marginBottom: theme.spacing(5),
  },
  titleAndToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    pagination: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        title: function ([{ dataset, dataIndex }]) {
          const row = dataset.tooltips[dataIndex];
          const { cantidad, periodHuman } = row;
          return `${cantidad} escuchas fraudulentas ${periodHuman}`;
        },
      },
    },
  },
};

const getStyleConfigurations = () => {
  return {
    backgroundColor: "rgba(255, 0, 0, 0.5)",
    borderColor: "rgba(255, 0, 0, 0.5)",
    borderWidth: 2,
    pointRadius: 3,
    pointHoverRadius: 5,
    tension: 0.3,
  };
};

const FraudChartSpoty = ({ data }) => {
  const [totPages, setTotPages] = useState(0);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [tooltips, setTooltips] = useState([]);

  const [pageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(1);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriodInSpoty } = data;

      const labels = fraudulentByPeriodInSpoty.map((row) => row.periodHuman);
      setLabels(labels);

      const dataset = {
        label: "Spotify",
        data: fraudulentByPeriodInSpoty.map((row) => row.cantidad),
        backgroundColor: "rgba(255, 0, 0, 0.5)",
      };
      setDatasets([dataset]);

      // tooltips
      const _tooltips = fraudulentByPeriodInSpoty.map((row) => row);
      setTooltips(_tooltips);
    }
  }, [data]);

  useEffect(() => {
    const numPages = Math.ceil(labels.length / pageSize);
    setTotPages(numPages);

    const paginate = (arr) => {
      const start = page * pageSize * -1;
      const end = labels.length - pageSize * (page - 1);
      return arr.slice(start, end);
    };

    if (labels.length && datasets.length) {
      const _datasets = datasets.map((dataset) => {
        return {
          ...dataset,
          data: paginate(dataset.data || []),
          tooltips: paginate(tooltips || []),
        };
      });

      const _chartData = {
        labels: paginate(labels),
        datasets: _datasets,
      };

      setChartData(_chartData);
    }
  }, [labels, datasets, page, pageSize]);

  const classes = useStyles();

  if (!chartData) return null;

  // pagination controls
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < totPages) {
      setPage(page + 1);
    }
  };

  const changePageSize = (size) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.titleAndToolbar}>
        <Typography variant="h5" gutterBottom>
          Escuchas fraudulentas Spotify
        </Typography>

        <div className={classes.pagination}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              disabled={page >= totPages}
              variant="contained"
              color="primary"
              onClick={nextPage}
            >
              <ChevronLeft />
            </Button>
            <Button
              disabled={page <= 1}
              variant="contained"
              color="primary"
              onClick={prevPage}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>

          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button
              variant="contained"
              disabled={pageSize === 3}
              color="primary"
              onClick={() => changePageSize(3)}
            >
              3 meses
            </Button>
            <Button
              variant="contained"
              disabled={pageSize === 12}
              color="primary"
              onClick={() => changePageSize(12)}
            >
              12 meses
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <Bar data={chartData} options={options} />
    </Paper>
  );
};

export default FraudChartSpoty;
