import { Typography } from '@material-ui/core';

const Logo = ({ siteName, onClick }) => {
  if (!siteName) return null;

  return (
    <Typography variant="h6" color="inherit" onClick={onClick} style={{ cursor: "pointer" }}>
      {siteName}
    </Typography>
  );
};

export default Logo;
