import { useEffect, useState, useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import AccountContext from "../contexts/AccountContext";
import AuthUserContext from "./AuthUserContext";
import { useHistory } from "react-router-dom";
import UserMenu from "./UserMenu";
import UserAccountMenu from "./UserAccountMenu";
import AccountMenu from "./navigation/AccountMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1200,
    position: "fixed",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  left: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  appBar: {
    color: "rgb(204, 170, 102)",
    background: "black",
    borderBottom: "5px solid rgb(204, 170, 102)",
  },
}));

const Navigation = () => {
  const [siteName, setSiteName] = useState("");
  const account = useContext(AccountContext);
  const user = useContext(AuthUserContext);
  const userAccount = user?.accountFull;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (account?.name || account?.nameDisplay) {
      setSiteName(account.nameDisplay || account.name);
    }
  }, [account]);

  if (!user) return null;

  return (
    <div className={classes.root}>
      <AppBar
        elevation={2}
        className={classes.appBar}
        style={{
          backgroundColor: account?.colors?.navbarBackground,
          borderBottom: "5px solid " + account?.colors?.navbarPrimary,
          color: account?.colors?.navbarText,
        }}
      >
        <Toolbar className={classes.toolbar}>
          <>
            <div className={classes.left}>
              <UserMenu user={user} account={account} />
              <AccountMenu
                user={user}
                account={account}
                userAccount={userAccount || {}}
              />
              {/* <sup
                style={{
                  fontSize: "0.7em",
                  opacity: 0.7,
                  marginLeft: "2em",
                }}
              >
                v.{version}
              </sup> */}
            </div>
            <div>
              <UserAccountMenu
                distributor={siteName}
                user={user}
                history={history}
              />
            </div>
          </>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navigation;
