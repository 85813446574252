// @ts-check

import {
  getAlbumTitle,
  makeCopyrightFields,
  normalizeTitle,
} from "../../../business-objects";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */

/**
 * @param {object} updates
 * @param {Album} album
 * @param {FieldEditable} field
 * @param {any=} newValue
 * @return {object}
 */
const _sideEffectCommon = (updates, album, field, newValue) => {
  if ("subaccount" in updates) {
    updates = {
      ...updates,
      ...makeCopyrightFields({
        ...album,
        subaccount: updates.subaccount,
      }),
    };
  }

  if ("release" in updates) {
    updates = {
      ...updates,
      ...makeCopyrightFields({
        ...album,
        release: updates.release,
      }),
    };
  }

  if (field.path === "title" || field.path.endsWith("/title")) {
    updates = {
      ...updates,
      [field.path]: normalizeTitle(
        [newValue],
        album.lyrics_lang,
        album.disabledAutoTitle
      ),
    };
  }

  // when auto-format is re-enabled, normalize all album and track titles
  if (field.path === "disabledAutoTitle" && !newValue) {
    // album title
    updates = {
      ...updates,
      title: normalizeTitle([album.title]),
    };

    // track titles
    Object.keys(album.tracks).forEach((key) => {
      updates[`tracks/${key}/title`] = normalizeTitle([
        album.tracks[key].title,
      ]);
    });
  }

  // verify title change
  const titlelabel = getAlbumTitle({ ...album, ...updates });

  if (titlelabel && titlelabel !== album.titleLabel) {
    updates.titleLabel = titlelabel;
  }

  return updates;
};

export default _sideEffectCommon;
