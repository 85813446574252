//@ts-check
import { trim, isEmpty, upperFirst, toLower } from "lodash";

const ALWAYS_LOWER_REGEXP = new RegExp("feat.", "i");

const EN_CAPITALIZED_REGEXP = new RegExp(
  "^(feat.|a|an|and|as|but|for|from|nor|of|or|so|the|to|yet|at|by|for|from|in|into|of|off|on|onto|out|over|to|up|with)$",
  "i"
);
const ES_CAPITALIZED_REGEXP = new RegExp(
  "^(feat.|a|e|las|por|al|el|los|un|de|en|o|una|del|la|para|y|am|pm)$",
  "i"
);

const PT_CAPITALIZED_REGEXP = new RegExp(
  "^(feat.|a|das|à|de|ao|do|aos|dos|as|e|pra|pro|ou|um|para|uma|nas|pela|no|pelas|nos|pelo|o|pelos|os|por|às|em|da|na)$",
  "i"
);

/**
 * @param {Array<string>} words
 * @param {RegExp} regExpExceptions
 * @returns {Array<string>}
 */
const _capitalizeFirstAll = (words, regExpExceptions) => {
  if (isEmpty(words)) {
    return [];
  }

  const lastIndex = words.length - 1;

  return words.map((word, index) =>
    _capitalizeFirst(word, index === 0 || index === lastIndex, regExpExceptions)
  );
};

/**
 *
 * @param {string} text
 * @returns {Array<string>}
 */
const _splitWords = (text) => {
  if (isEmpty(text)) {
    return [];
  }
  return text.split(" ").map(trim);
};

/**
 * @param {string} word
 * @param {boolean} isFirstOrLast
 * @param {RegExp} regExceptions
 * @return {string}
 */
const _capitalizeFirst = (word, isFirstOrLast, regExceptions) => {
  word = trim(word);
  let firstChar = word.charAt(0);
  if (firstChar === "(" || firstChar === "[") {
    word = word.substring(1);
  } else {
    firstChar = "";
  }
  let toUpper =
    (firstChar !== "" || isFirstOrLast) && !ALWAYS_LOWER_REGEXP.test(word);
  toUpper = toUpper || !(!regExceptions || regExceptions.test(word));

  word = toUpper ? upperFirst(word) : word;
  return firstChar + word;
};

/**
 *
 * @param {string} text
 * @param {string} char
 * @param {RegExp} regExpExceptions
 * @returns {string}
 */
const _spaceAndCapitalBetweenChar = (text, char, regExpExceptions) => {
  const parts = text
    .split(char)
    .map((part, index) =>
      index > 0 ? _capitalizeFirst(part, true, regExpExceptions) : part
    );
  return parts.join(` ${char} `);
};

const _determineListExceptions = (language) => {
  language = toLower(language);
  switch (language) {
    case "english":
    case "en":
    case "eng":
      return EN_CAPITALIZED_REGEXP;
    case "spanish":
    case "español; castellano":
    case "es":
    case "spa":
      return ES_CAPITALIZED_REGEXP;
    case "portuguese":
    case "pt":
    case "cpp":
    case "por":
      return PT_CAPITALIZED_REGEXP;
    default:
      return ES_CAPITALIZED_REGEXP;
  }
};

/**
 *
 * @param {Array<string>} parts
 * @param {string} language - // name or language code ex: Spanish or spa
 * @returns {Array<string>}
 */
const _proccess = (parts, language) => {
  const regExpException = _determineListExceptions(language);

  return parts.map((part) => {
    part = toLower(trim(part));
    part = _spaceAndCapitalBetweenChar(part, "-", regExpException);
    part = _spaceAndCapitalBetweenChar(part, "/", regExpException);
    let words = _splitWords(part);
    words = _capitalizeFirstAll(words, regExpException);
    return words.join(" ");
  });
};

const _assemble = (parts) => {
  return parts
    .map((part, index) => {
      if (index === 0) {
        // first part without bracket
        return part;
      }
      // middle parts with curve bracket
      if (parts.length < 3 || index < parts.length - 1) {
        return `(${part})`;
      }
      // last part with square bracket
      return `[${part}]`;
    })
    .join(" ");
};

/**
 *
 * @param {Array<string>} titleParts
 * @param {string?} language - // name or language code ex: Spanish or spa
 * @return {string}
 */
export const normalizeTitle = (
  titleParts,
  language = "",
  autoFormatDisabled = false
) => {
  let parts;

  // if autoFormatDisabled is true, return the title as it is
  if (autoFormatDisabled) {
    parts = titleParts;
  } else {
    parts = _proccess(titleParts, language || "");
  }

  return _assemble(parts);
};

export default normalizeTitle;
