import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { MANAGE_ACCOUNT } from "../../../constants/routes";

const useStyles = makeStyles({
  root: {
    // width: 300,
  },
});

const AccountCard = ({ account, accountId }) => {
  const classes = useStyles();
  const history = useHistory();

  if (!account) {
    return null;
  }

  const handleOpen = () => {
    history.push(MANAGE_ACCOUNT.replace(":accountId", accountId));
  };

  return (
    <Card className={classes.root} onClick={handleOpen}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h6" component="h2">
            {account.name}
          </Typography>
          <Typography variant="body1">{account.owner_email}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AccountCard;
