const parseAccountText = (text, account) => {
  console.log(account);

  // helper function to convert an UPPERCASE_UNDERSCORE string to lowerCamelCase
  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
  };

  return text.replace(/__(\w+)__/g, (match, label) => {
    // try direct lowercase
    let key = label.toLowerCase();
    if (account.hasOwnProperty(key)) {
      return account[key];
    }

    // then try camelCase conversion
    key = toCamelCase(label);
    if (account.hasOwnProperty(key)) {
      return account[key];
    }

    // if no matching key is found, return the original label unchanged
    return match;
  });
};

export default parseAccountText;
