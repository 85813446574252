import { IconButton } from "@material-ui/core";
import { saveAs } from "file-saver";
import { functions } from "../../firebase";
import DownloadIcon from "@material-ui/icons/GetApp";

const downloadXlsx = functions.httpsCallable("getXlsx");

const DownloadXLSX = ({ period, tarifa, onWorking, uid }) => {
  const handleDownload = async (event) => {
    event.stopPropagation();
    console.log("Downloading...");

    try {
      onWorking(true);
      const result = await downloadXlsx({ period, tarifa, uid });

      const data = result.data;
      const blob = new Blob([new Uint8Array(data.file)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${period}.xlsx`);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      onWorking(false);
    }

    console.log("File downloaded.");
  };
  return (
    <IconButton onClick={handleDownload}>
      <DownloadIcon />
    </IconButton>
  );
};

export default DownloadXLSX;
