import { functions } from "../firebase";

export const search = async (q) => {
  const _search = functions.httpsCallable("searchArtists");
  return _search(q).then((r) => r.data);
};

export const getUserArtists = async () => {
  try {
    const _getArtists = functions.httpsCallable("getUserArtists");

    // const email = data.email || context.auth.token.email;

    const artists = await _getArtists();
    return await artists.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default {
  search,
  getUserArtists,
};
