import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import AccountContext from "../contexts/AccountContext";
import AuthUserContext from "./AuthUserContext";
import { doSignOut } from "../firebase/auth";
import { keys } from "lodash";

const getHost = () => {
  const { host } = window.location;

  switch (true) {
    case host.includes("localhost"):
      return "localhost";
    case host.includes("musik.digital"):
      return "musik.digital";
    case host.endsWith("musik-digital.web.app"):
    case host.endsWith("musik-digital.firebaseapp.com"):
      return "musik-digital.web.app";
    default:
      return host;
  }
};

const SubdomainEnforcer = () => {
  const { pathname } = useLocation();

  const account = useContext(AccountContext);
  const user = useContext(AuthUserContext);

  useEffect(() => {
    if (account && user) {
      //

      const { host, protocol, port } = window.location;
      const { subdomain } = account;

      const isMember = keys(account.members).includes(user.uid);
      const isOwner = account.owner === user.uid;

      // If the user is a member or owner, don't enforce subdomain
      if (isMember || isOwner) {
        return;
      }

      // If the user is not a member or owner, enforce subdomain in the account she belongs to
      if (subdomain && !host.startsWith(subdomain)) {
        // doSignOut();
        const domainHost = getHost();
        const portString = port ? `:${port}` : "";
        const correctUrl = `${protocol}//${subdomain}.${domainHost}${portString}${pathname}`;
        window.location.href = correctUrl;
      }
    }
  }, [account, user, pathname]);

  return null;
};

export default SubdomainEnforcer;

/*



*/
