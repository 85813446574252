import { db, auth, functions } from "./firebase";
import { capitalize, padStart } from "lodash";
import dateFormat from "dateformat";
import arrayMove from "array-move";
import { keys } from "lodash";
import { SocialLinks } from "social-links";

// User API

export const saveFingerprint = async (fingerprint) => {
  if (auth.currentUser !== null) {
    const refPath = `fingerprints/${auth.currentUser.uid}/fingerprints`;
    return db.ref(refPath).child(fingerprint).set(true);
  }
};

export const saveUserSocialNetworks = async (socialNetworks) => {
  if (auth.currentUser !== null) {
    const refPath = `users/${auth.currentUser.uid}/socialNetworks`;

    keys(socialNetworks).forEach((key) => {
      if (!socialNetworks[key] || socialNetworks[key] === "") {
        delete socialNetworks[key];
      } else {
        const socialLinks = new SocialLinks();

        if (key && key !== "mastodon") {
          const isValid = socialLinks.isValid(key, socialNetworks[key]);
          if (isValid) {
            socialNetworks[key] = socialLinks.sanitize(
              key,
              socialNetworks[key]
            );
          }
        }
      }
    });

    return db.ref(refPath).update({ ...socialNetworks });
  }
};

export const deleteSocialNetwork = async (sn) => {
  if (auth.currentUser !== null) {
    const refPath = `users/${auth.currentUser.uid}/socialNetworks/${sn}`;
    return db.ref(refPath).remove();
  }
};

export const getUserBalance = async () => {
  let balance = 0;
  if (auth.currentUser !== null) {
    const userRef = db.ref("users/" + auth.currentUser.uid);
    await userRef.once("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.key === "balance") {
          balance = child.val();
        }
      });
    });
    // if (!balance) balance = initBalance(userRef);
  }
  return balance;
};

// export const initBalance = (userRef) => {
//   db.ref(userRef)
//   .child('balance')
//   .set(newBalance)
//   .then(callback);
// };

export const paymentService = (token, amount) => {
  let payment = { token, amount };
  db.ref(`/stripePayments/${auth.currentUser.uid}`).push(payment);
};

export const setUserBalance = async (amount, callback) => {
  const balance = await getUserBalance();
  let newBalance;
  if (auth.currentUser !== null && amount) {
    const refPath = `users/${auth.currentUser.uid}`;
    newBalance = parseFloat(balance ? balance : 0) + parseFloat(amount);
    db.ref(refPath).update({ balance: newBalance }).then(callback);
    // registerCheckout({'balance previo': balance, 'importe': amount, 'nuevo balance': newBalance}, "recarga");
  }
  return newBalance;
};

// export const getFieldFromCatalog = async (upc, field) => {
//   let fieldValue;
//   await db.ref("catalog").once("value", (snapshot) => {
//     snapshot.forEach((child) => {
//       if (child.key === upc) {
//         let catalogObj = child.val();
//         fieldValue = catalogObj[field];
//       }
//     });
//   });
//   return fieldValue;
// };

// TODO: refactor a nueva forma
export const replaceCatalog = (upc) => {
  const catalogOnEditRef = db.ref(`catalogOnEdit/${upc}`);
  let newCatalog;
  let dataChanged;
  catalogOnEditRef.on("value", function (snapshot) {
    newCatalog = snapshot.val();
    if (newCatalog) {
      dataChanged = newCatalog.inCart;
      newCatalog.inCart = null;
    }
  });
  registerCheckout(dataChanged, upc);
  if (newCatalog) {
    db.ref(`catalog/${upc}`).update(newCatalog);
    catalogOnEditRef.remove();
  }
};

// TODO: refactor a nueva forma
export const registerCheckout = (dataChanged, upc) => {
  if (auth.currentUser !== null && dataChanged) {
    let timestamp = Date.now();
    let changed = {};
    if (upc) dataChanged.upc = upc;
    changed[timestamp] = dataChanged;
    db.ref(`checkoutHistory/${auth.currentUser.uid}`).update({ ...changed });
  }
};

export const getHistory = () => {
  if (auth.currentUser !== null) {
    let arrChanges = [];
    db.ref(`checkoutHistory/${auth.currentUser.uid}`).on(
      "value",
      (snapshot) => {
        snapshot.forEach((snap) => {
          let changes = snap.val();
          changes.timestamp = snap.key;
          arrChanges.push(changes);
        });
      }
    );

    return arrChanges;
  }
};

export const cancelAlbumOnEdit = async (upc) => {
  const catalogOnEditRef = db.ref(`catalogOnEdit/${upc}`);

  const updates = {
    status: "canceled",
  };
  try {
    db.ref(`catalog/` + upc).update(updates);
    catalogOnEditRef.remove();
  } catch (err) {
    console.log(err);
  }
};

// ACCOUNTS API ---------------------------------------------------------------

const getAccountByProperty = functions.httpsCallable("getAccountByProperty");

export const getAccountBySubdomain = async () => {
  const result = await getAccountByProperty({
    name: "subdomain",
    value: window.location.hostname.split(".")[0],
  });

  // not found
  if (!result.data) {
    console.log("account not found by domain");
    return null;
  }

  return result.data;
};

export const getAccountName = async () => {
  if (!auth.currentUser) return null;

  const snap = await db.ref("users").child(auth.currentUser.uid).once("value");
  const user = snap.val();
  return user.account;
};

export const getAccounts = async () => {
  const snap = await db.ref("accounts").once("value");
  return snap.val();
};

export const getAccount = async (search) => {
  const key = keys(search)[0];
  const value = search[key];

  const snap = await db
    .ref("accounts")
    .orderByChild(key)
    .equalTo(value.toUpperCase())
    .once("value");

  let account;

  snap.forEach((child) => {
    account = child.val();
  });

  return account;
};

//

export const doCreateUser = async (
  id,
  email,
  countryISOCode,
  phonePrefix,
  phoneNumber,
  account
) => {
  try {
    return db.ref(`users/${id}`).set({
      account,
      email,
      countryISOCode,
      phone: `${phonePrefix} ${phoneNumber}`,
      restrictions: {
        contentId: true,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const doCreateUserAccess = (id, payload) => {
  const defaultValue = {
    payments: false,
    royalties: false,
  };

  db.ref(`access/${id}`).set(payload || defaultValue);
};

// export const onceGetUsers = () => db.ref("users").once("value");

export const getExtendedUserAsync = async () => {
  if (!auth.currentUser) return null;
  const snap = await db.ref("users").child(auth.currentUser.uid).once("value");
  return snap.val();
};

// Config API

// export const getConfig = (callback) => db.ref('config').on('value', callback)
export const onceGetConfig = () => db.ref("config").once("value");

// reports
export const onceGetReports = () =>
  db.ref("reports/apple").limitToLast(1).once("value");

/**
 * make changes to album fields
 */

export const getStatusOnEdit = async (upc) => {
  const data = await db.ref(`catalogUnedited/${upc}`).once("value");
  return data.exists();
};

export const fetchAlbumUnedited = async (upc) => {
  const data = await db.ref(`catalogUnedited/${upc}`).once("value");
  return data.val();
};

export const updateAlbumFields = async (
  upc,
  updates,
  callback = () => {},
  status
) => {
  if (!upc) return false;

  await makeUneditedCopyBeforeChange(upc);

  // update all paths
  const albumPath = `catalog/${upc}`;
  return db.ref(albumPath).update(updates).then(callback);
};

export const startAlbumEdition = async (upc) => {
  if (!upc) return false;

  await makeUneditedCopyBeforeChange(upc);
};

// unedited album backup before making changes (only when status is live)
const makeUneditedCopyBeforeChange = async (upc) => {
  if (!upc) return false;

  const albumPath = `catalog/${upc}`;
  const currentAlbumSnap = await db.ref(albumPath).once("value");
  const currentAlbum = currentAlbumSnap.val();

  // only w/ status live
  if (currentAlbum.status !== "live") {
    return false;
  }

  // make copy
  const uneditedRef = db.ref(`catalogUnedited/${upc}`);
  await uneditedRef.set(currentAlbum);

  // set new status
  await db.ref(albumPath).update({ status: "live_updating" });
};

/**
 * Add album artist
 * @param {string} upc
 * @param {string} role
 * @param {function=} callback
 * @return {Promise<string>} Retorna promesa con el id del nuevo artista
 */
export const addAlbumArtist = async (upc, role, callback = null) => {
  if (!upc || !role) return false;

  await makeUneditedCopyBeforeChange(upc);

  const refAlbumArtists = db.ref(`catalog/${upc}/artists`);
  const newArtistRef = refAlbumArtists.push();
  const key = newArtistRef.key;

  return newArtistRef
    .set({ name: "", role, timestamp: Date.now() })
    .then(() => {
      if (callback) {
        callback(key);
      }
      return key;
    });
};

/**
 * Add Track artist
 * @param {string} upc
 * @param {string} isrc
 * @param {string} role
 * @param {function=} callback
 * @return {Promise<string>} Retorna promesa con el id del nuevo artista
 */
export const addTrackArtist = async (upc, isrc, role, callback = null) => {
  if (!upc || !isrc || !role) return false;

  await makeUneditedCopyBeforeChange(upc);

  const refTrackArtists = db.ref(`catalog/${upc}/tracks/${isrc}/artists`);

  const newArtistRef = refTrackArtists.push();
  const key = newArtistRef.key;

  return newArtistRef
    .set({ name: "", role, timestamp: Date.now() })
    .then(() => {
      if (callback) {
        callback(key);
      }
      return key;
    });
};

/**
 * add new track to album
 */
export const addAlbumTrack = async (upc, callback) => {
  if (!upc) return false;
  const refAlbumFormat = db.ref(`catalog/${upc}/format`);
  const refAlbumTracks = db.ref(`catalog/${upc}/tracks`);
  const tracksSnap = await refAlbumTracks.once("value");
  const numTracks = tracksSnap.numChildren();
  const [isrc] = await getISRCblock(1);

  await refAlbumTracks
    .child(isrc)
    .set({ title: "(track sin título)", order: numTracks + 1, isrc })
    .then(() => {
      refAlbumFormat.set(
        numTracks >= 1 ? (numTracks < 4 ? "EP" : "Album") : "Single"
      );
      callback && callback();
    });
  return isrc;
};

/**
 * Delete track
 */
export const deleteAlbumTrack = async (upc, isrc, position, callback) => {
  if (!upc || !isrc) return false;

  const refAlbum = db.ref(`catalog/${upc}`);
  const refAlbumTracks = refAlbum.child(`tracks`);

  const updates = {};

  // update tracks position on those that come after deleted track
  const snap = await refAlbumTracks
    .orderByChild("order")
    .startAt(position)
    .once("value");

  snap.forEach((trackSnap) => {
    const track_isrc = trackSnap.key;
    const track = trackSnap.val();

    if (track_isrc === isrc) {
      updates[`${track_isrc}`] = null;
    } else {
      updates[`${track_isrc}/order`] = track.order > 1 ? track.order - 1 : 1;
    }
  });

  // save tracks changes
  await refAlbumTracks.update(updates);

  //  SIDE EFFECTS /////////////////////////////
  const updatesSE = {};
  const tracksSnap = await refAlbumTracks.once("value");
  const numTracks = tracksSnap.numChildren(); // num tracks after deletion

  // update album format
  updatesSE[`format`] =
    numTracks > 1 ? (numTracks < 5 ? "EP" : "Album") : "Single";

  // when album becomes a single
  if (numTracks === 1) {
    const tracks = tracksSnap.val();
    const firstTrackIsrc = Object.keys(tracks)[0];

    const albumVersionSnap = await refAlbum.child(`version`).once("value");
    const albumVersion = albumVersionSnap.val();

    const albumTitleSnap = await refAlbum.child(`title`).once("value");
    const albumTitle = albumTitleSnap.val();

    updatesSE[`tracks/${firstTrackIsrc}/title`] = albumTitle;
    updatesSE[`tracks/${firstTrackIsrc}/version`] = albumVersion;
  }

  await refAlbum.update(updatesSE);
};

/**
 * Order tracks
 */
export const orderAlbumTrack = async (upc, oldIndex, newIndex, callback) => {
  if (
    !upc ||
    typeof oldIndex === "undefined" ||
    typeof newIndex === "undefined"
  )
    return false;

  const refAlbumTracks = db.ref(`catalog/${upc}/tracks`);
  const updates = {};

  const snap = await refAlbumTracks.orderByChild("order").once("value");

  if (!snap.exists()) return false;

  const isrcs = [];

  snap.forEach((trackSnap) => {
    isrcs.push(trackSnap.key);
  });

  const newOrder = arrayMove(isrcs, oldIndex, newIndex);

  newOrder.forEach((isrc, index) => {
    if (isrcs.indexOf(isrc) !== index) {
      updates[`${isrc}/order`] = index + 1;
    }
  });

  refAlbumTracks.update(updates);
};

/**
 * get ISRCs (one or more)
 */
export const getISRCblock = async (num = 1) => {
  const isrcRef = db.ref("isrc");
  const reserved = [];

  const ISRC = {
    country: "MX",
    company: 283,
    year: dateFormat("yy"),
  };

  // update isrc  counter
  await isrcRef.transaction((lastAssigned) => {
    if (lastAssigned) {
      for (var i = 1; i <= 1; i++) {
        reserved.push(lastAssigned + i);
      }
      return lastAssigned + num;
    }
    return lastAssigned;
  });

  return reserved.map(
    (order) =>
      `${ISRC.country}${ISRC.company}${ISRC.year}${padStart(order, 5, "0")}`
  );
};

export const getUIversion = async () =>
  db
    .ref("config/uiversion")
    .once("value")
    .then((snap) => snap.val());

export const isrcExists = async (isrc) => {
  const snap = await db.ref("isrcToUpc").child(isrc).once("value");
  return snap.exists();
};
