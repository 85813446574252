import React, { useState, useEffect, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import { firebase } from "../firebase";
import parse from "html-react-parser";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import AccountContext from "../contexts/AccountContext";
import parseAccountText from "../utils/parseAccountText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formPaper: {
    maxWidth: 1100,
    minHeight: "100vh",
    margin: "10vh auto",
    padding: theme.spacing(5),
  },
}));

const Page = ({ slug }) => {
  const [content, setContent] = useState("");
  const [heading, setHeading] = useState("");

  const account = useContext(AccountContext);
  const classes = useStyles();

  useEffect(() => {
    const fetchContent = async () => {
      const snapshot = await firebase.db.ref(`pages/${slug}`).once("value");
      const _body = parseAccountText(snapshot.val().body, account);
      const _title = parseAccountText(snapshot.val().title, account);

      setContent(_body);
      setHeading(_title);
    };

    if (account) {
      fetchContent();
    }
  }, [slug, account]);

  if (!heading || !content) {
    return (
      <CircularProgress variant="indeterminate" className={classes.loading} />
    );
  }

  return (
    <Paper elevation={1} className={classes.formPaper}>
      <Typography variant="h4" gutterBottom>
        {parse(heading)}
      </Typography>
      <div className={classes.body}>{parse(content)}</div>
    </Paper>
  );
};

export default Page;
