import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import SocialNetwork from "./SocialNetwork";
import { SocialLinks } from "social-links";
import { Box, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

const socialLinks = new SocialLinks();

const SNField = ({ sn, value, hanldeDelete }) => {
  if (!value) return null;

  const [isValidLink, setIsValidLink] = useState(false);
  const [sanitizedLink, setSanitizedLink] = useState("");

  const classes = useStyles();

  useEffect(() => {
    const isValid = socialLinks.isValid(sn, value);
    setIsValidLink(isValid);

    if (sn && isValid) {
      let sanitized = socialLinks.sanitize(sn, value);

      // ñapa twitter del orto
      if (sn === "twitter") {
        sanitized = sanitized.replace("twitter", "x");
      }

      setSanitizedLink(sanitized);
    }
  }, [sn, value]);

  if (!value || !isValidLink) return null;

  return (
    <Box className={classes.root}>
      <SocialNetwork name={sn} />
      <TextField
        autoFocus
        margin="dense"
        id={sn}
        label={sn}
        fullWidth
        variant="standard"
        value={sanitizedLink}
        disabled={true}
      />
      <ButtonBase onClick={() => hanldeDelete(sn)}>
        <Close />
      </ButtonBase>
    </Box>
  );
};

export default SNField;
