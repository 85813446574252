import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import stringToColor from "../utils/stringToColor";

const useStyles = makeStyles({
  root: {
    backgroundColor: (user) => stringToColor(user.email),
  },
});

export default ({ user }) => {
  const classes = useStyles(user);

  return (
    <Avatar
      src={user.photoURL}
      className={classes.root}
      sx={{ width: 32, height: 32 }}
    >
      {(user.displayName || user.email)[0].toUpperCase()}
    </Avatar>
  );
};
