import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { keys } from "lodash";
import Semaforo from "./Semaforo";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    display: "flex",
    gap: theme.spacing(2),
  },
  light: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    backgroundColor: "green",
    marginRight: theme.spacing(2),
  },
  info: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const FraudLight = ({ data }) => {
  const [percentage, setPercentage] = useState(0);
  const [color, setColor] = useState("green");
  // const [totalStreams, setTotalStreams] = useState(0);
  // const [totalFraudulent, setTotalFraudulent] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      const { fraudulentByPeriod, streamsByTrackAndPeriod, fraudLight } = data;

      // FRAUDULENT DATA
      const totalFraudulent = fraudLight.totalFraudulent;

      // NORMAL STREAMS DATA
      const totalStreamsCount = streamsByTrackAndPeriod
        .filter((p) => fraudLight.periods.includes(p.period))
        .reduce((acc, curr) => acc + curr.cantidad, 0);

      const maxAllowedFraud = Math.round(totalStreamsCount * 0.01);

      // console.log("totalStreamsCount", totalStreamsCount);
      // console.log("totalFraudulent", totalFraudulent);
      // console.log("maxAllowedFraud", maxAllowedFraud);

      const percentageNumber = parseFloat(
        (totalFraudulent / maxAllowedFraud) * 100
      );
      setPercentage(percentageNumber);

      let newColor = "green";
      if (percentageNumber > 30) newColor = "yellow";
      if (percentageNumber > 50) newColor = "red";
      setColor(newColor);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Semaforo color={color} />

      <div className={classes.info}>
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          <Typography variant="h3">{percentage.toFixed(0)}%</Typography>

          <Typography variant="body1">del umbral máximo tolerable</Typography>
        </div>

        <Typography variant="body1" color="textSecondary">
          🙏🏽 {""}
          <a
            href="https://artists.spotify.com/es-419/artificial-streaming"
            target="_blank"
          >
            Todo lo que deberías saber...
          </a>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          👉🏾 <strong>PRO TIP:</strong>{" "}
          <a href="https://www.artist.tools/bot-checker" target="_blank">
            Utiliza esta herramienta para entender de dónde vienen.
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default FraudLight;
