// @ts-check
import { db, functions } from "../../../firebase";

/** @typedef {import("../../../business-objects/album").Album} Album */
/** @typedef {import("../../../business-objects/album").Track} Track */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable */
/** @typedef {import("../../../business-objects/album.fieldEditable").FieldActionType} FieldActionType */
/** @typedef {import("../../../reducers/store").StateStore} StateStore */
/** @typedef {import("../../../reducers/store").StateGetter} StateGetter */
/**
 * @typedef {{
 *  storageLocation: string;
 *  filename: string;
 *  extension: string;
 *  originalFilename: string
 * }} NewAudioMeta
 */

const replaceISRCinAudioFilename = functions.httpsCallable(
  "replaceISRCinAudioFilename"
);

/**
 * Generate update object for ISRC change in track metadata
 * @param {Track} trackMetadata
 * @param {string} oldISRC
 * @param {string} newISRC
 * @returns {{ [key: string]: any }}
 */
const makeUpdatesTrackMetadata = (trackMetadata, oldISRC, newISRC) => {
  const { filename, storageLocation } = trackMetadata;

  const updates = {
    [`tracks/${oldISRC}`]: null,
    [`tracks/${newISRC}`]: {
      ...trackMetadata,
      isrc: newISRC,
      filename: filename.replace(oldISRC, newISRC),
      storageLocation: storageLocation.replace(oldISRC, newISRC),
    },
  };

  return updates;
};

/**
 * Add a field to track
 * @param {Album} album
 * @param {string} oldISRC
 * @param {string} newISRC
 * @return {Function}
 */
export const trackReplaceISRC = (album, oldISRC, newISRC) => {
  /**
   * @param {function} dispatch
   */
  return async function thunk(dispatch) {
    const isISRCUsed = await db.isrcExists(newISRC);

    if (isISRCUsed) {
      return false;
    }

    // UPDATE FIELD VALUE
    const { upc } = album;
    const trackMetadata = album.tracks[oldISRC];
    const updates = makeUpdatesTrackMetadata(trackMetadata, oldISRC, newISRC);
    await db.updateAlbumFields(upc, updates);

    // RENAME AUDIO FILE
    await replaceISRCinAudioFilename({
      trackMetadata,
      newISRC,
    });

    return true;
  };
};
