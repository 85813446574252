// @ts-check
import React, { useMemo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import AlbumCover from "../../../../../components-cross/AlbumCover";

import {
  getAlbumTitle,
} from "../../../../../business-objects";

/** @typedef {import('../../../../../business-objects/album').Album} Album */
/** @typedef {import('../../../../../business-objects/album').Artist} Artist */
/** @typedef {import('../../../../../business-objects/album').Track} Track */

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    backgroundColor: "#eeeeee",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  headerInner: {
    maxWidth: "1100px",
    display: "flex",
    position: "relative",
    margin: "auto",
    gap: theme.spacing(2),
    flexWrap: "nowrap",
  },
  albumCover: {
    width: "120px",
    maxWidth: "120px",
    height: "120px",
    cursor: "pointer",
    borderRadius: "0.2rem",
    boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.25)",
    "& > img": {
      borderRadius: "0.2rem",
      width: "120px",
      height: "120px",
    },
  },
  albumDetail: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "relative",
  },
  editButton: {
    marginLeft: theme.spacing(3),
  },
  avatarImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },
  [theme.breakpoints.down("xs")]: {
      headerInner: {
        flexWrap: "wrap",
      },

      albumCover: {
        width: "100%",
        height: "120px",
        // marginBottom: theme.spacing(2),
        marginRight: "0",
        "& > img": {
          width: "100% !important",
          height: "120px !important",
        },
      },
    },
}));

const HeaderAlbum = ({ album }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerRoot}>
      <div className={classes.headerAlbum}>
        <div className={classes.headerInner}>
          <AlbumCover className={classes.albumCover} album={album} />

          <div className={classes.albumDetail}>
            <Typography variant="h5" className={classes.albumTitle}>{getAlbumTitle(album)}</Typography>

            <div className={classes.belowTitle}>
              {album.genreSubgenre && (
                <>
                  <span className="field">
                    {album.genreSubgenre.genre}/{album.genreSubgenre.subgenre}
                  </span>
                  {album.version && (
                    <>
                    <div className="fieldSeparator">·</div>
                    <span className="field">{album.version}</span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAlbum;
