import React, { useEffect, useState, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { firebase, functions, fbroot } from "../firebase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import withAuthorization from "./withAuthorization";
import { RecaptchaVerifier, PhoneAuthProvider } from "firebase/auth";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import normalizePhoneNumber from "phone";
import translateError from "../utils/translateError";
import PrefixSelect from "./PhonePrefixSelect";
import AppConfig from "./AppConfig";
import AuthUserContext from "./AuthUserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1101,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    overflow: "hidden",
  },
  verifyCard: {
    minWidth: 275,
    maxWidth: "95vw",
    margin: "auto",
    padding: theme.spacing(1),
    "& strong": {
      color: theme.palette.secondary.main,
      display: "block",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

// Función en la nube para vincular cuentas mediante teléfono
const linkAccountsByPhone = functions.httpsCallable("linkAccountByPhone");

const PhoneVerification = () => {
  const user = useContext(AuthUserContext);
  const recaptchaContainerRef = useRef(null);
  const classes = useStyles();

  const [snack, setSnack] = useState({ open: false, message: "" });
  const [phoneWithPrefix, setPhoneWithPrefix] = useState("");
  const [countryISOCode, setCountryISOCode] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneNumber, _setPhoneNumber] = useState("");
  const [formNotValid, setFormNotValid] = useState(true);
  const [needVerify, setNeedVerify] = useState(false);
  const [working, setWorking] = useState(false);
  const [recaptcha, setRecaptchaVerifier] = useState(null);

  // Determinar si se requiere verificación telefónica
  useEffect(() => {
    const checkNeedVerification = async () => {
      if (!user) return;

      const hasPhoneProvider = user.providerData?.some(
        (provider) => provider.providerId === "phone"
      );
      if (hasPhoneProvider) return;

      const { uid } = user;
      const snap = await fbroot
        .database()
        .ref("users")
        .child(uid)
        .child("parentAccount")
        .once("value");
      const isGrouped = snap.exists();
      const groupParentId = snap.val();
      const isGroupParent = uid === groupParentId;

      // Se requiere verificación si no está agrupado o si es el padre del grupo
      if (!isGrouped || (isGrouped && isGroupParent)) {
        setNeedVerify(true);
      }
    };

    checkNeedVerification();
  }, [user]);

  // Pre-popular número telefónico si existe en el usuario
  useEffect(() => {
    if (user && needVerify) {
      const { phoneNumber, phonePrefix, countryISOCode } = user;
      setCountryISOCode(countryISOCode || "");
      setPhonePrefix(phonePrefix || "");
      _setPhoneNumber(phoneNumber || "");
    }
  }, [user, needVerify]);

  // Combina prefijo y número para la verificación
  useEffect(() => {
    if (phoneNumber) {
      setPhoneWithPrefix(`${phonePrefix}${phoneNumber}`);
    }
  }, [phoneNumber, phonePrefix]);

  // Validación del formato del teléfono
  useEffect(() => {
    if (!phoneWithPrefix) return;

    const isValid = normalizePhoneNumber(phoneWithPrefix, {
      country: countryISOCode,
      validateMobilePrefix: true,
    }).isValid;

    setFormNotValid(!isValid);
  }, [phoneWithPrefix, countryISOCode]);

  // Inicialización de reCAPTCHA
  useEffect(() => {
    if (needVerify && recaptchaContainerRef.current && !recaptcha) {
      const recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainerRef.current,
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA verificado:", response);
          },
        },
        firebase.auth
      );
      window.recaptcha = recaptchaVerifier;
      setRecaptchaVerifier(recaptchaVerifier);
    }
  }, [needVerify, recaptcha]);

  // Función para actualizar el número (elimina ceros iniciales)
  const setPhoneNumber = (num) => _setPhoneNumber(num.replace(/^\s*0+/, ""));

  // Mostrar mensaje de error
  const errorSnack = ({ message }) => setSnack({ open: true, message });

  // Cerrar mensaje de error
  const handleCloseSnack = () => {
    setSnack({ open: false, message: "" });
  };

  // Envía SMS para autenticación y vincula la cuenta con el número telefónico
  const submitPhoneNumberAuth = async () => {
    setWorking(true);
    try {
      const provider = new PhoneAuthProvider(firebase.auth);
      const verificationId = await provider.verifyPhoneNumber(
        phoneWithPrefix,
        window.recaptcha
      );

      const verificationCode = window.prompt(
        "Escribe el código que te hemos enviado."
      );
      if (!verificationCode) {
        throw new Error("Código de verificación no proporcionado.");
      }

      const phoneCredential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      await firebase.auth.currentUser.linkWithCredential(phoneCredential);

      // Guarda el número telefónico en la base de datos
      await firebase.db
        .ref("users")
        .child(user.uid)
        .child("phone")
        .set(phoneWithPrefix);

      setNeedVerify(false);
    } catch (err) {
      const error = translateError(err);
      if (error.code !== "auth/account-exists-with-different-credential") {
        errorSnack(error);
      } else {
        const confirmLink = window.confirm(
          `El número ${phoneWithPrefix} está asociado a otra cuenta. ¿Deseas vincular tus cuentas?`
        );
        if (confirmLink) {
          const result = await linkAccountsByPhone(phoneWithPrefix);
          if (result.data === "ok") {
            setNeedVerify(false);
          }
        }
      }
    } finally {
      setWorking(false);
    }
  };

  // Actualiza el prefijo y el código del país
  const handlePhoneChange = (event, child) => {
    setPhonePrefix(child.props.prefix);
    setCountryISOCode(child.props.code);
  };

  if (!needVerify) return null;

  return (
    <AppConfig>
      <div className={classes.root}>
        <Card className={classes.verifyCard}>
          <CardContent>
            <Typography variant="h5" paragraph>
              Verificación SMS
            </Typography>
            <Typography variant="body2" paragraph>
              Recibirás un código en tu celular para completar la verificación.
            </Typography>
            <div ref={recaptchaContainerRef} id="recaptcha-container"></div>
            <PrefixSelect
              onChangePrefix={handlePhoneChange}
              onChangePhoneNumber={(e) => setPhoneNumber(e.target.value)}
              phonePrefix={phonePrefix}
              phoneNumber={phoneNumber}
              countryISOCode={countryISOCode}
            />
          </CardContent>
          <CardActions style={{ justifyContent: "space-between", padding: 16 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={formNotValid || working}
              onClick={submitPhoneNumberAuth}
            >
              Enviar SMS a {phonePrefix} {phoneNumber}
            </Button>
            {working && <CircularProgress size="2em" />}
          </CardActions>
        </Card>
        <Snackbar
          open={snack.open}
          onClose={handleCloseSnack}
          message={<span id="message-id">{snack.message}</span>}
        />
      </div>
    </AppConfig>
  );
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(PhoneVerification);
