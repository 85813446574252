import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { getAccounts } from "../../../firebase/db";
import keys from "lodash/keys";
import AccountCard from "./AccountCard";
import isAdmin from "../../../utils/isAdmin";
import isSafeAdmin from "../../../utils/isSafeAdmin";
import { useHistory, useParams } from "react-router-dom";
import AccountEditForm from "./AccountEditForm";

const canUse = () => {
  return isAdmin() || isSafeAdmin();
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    maxWidth: "90vw",
    // display: "grid",
    // gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    // gap: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: theme.spacing(5),
  },
  account: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(5),
  },
}));

const ManageAccounts = () => {
  const [accounts, setAccounts] = useState([]);

  const { accountId } = useParams();

  console.log("accountId", accountId);

  useEffect(() => {
    (async () => {
      const allAccounts = await getAccounts();
      if (allAccounts) {
        setAccounts(allAccounts);
      }
    })();
  }, []);

  const classes = useStyles();
  const history = useHistory();

  if (!canUse()) {
    history.push("/");
  }

  if (accountId) {
    console.log("accountId", accountId);
    return <AccountEditForm />;
  }

  return (
    <div className={classes.root}>
      {keys(accounts).map((id) => {
        return (
          <AccountCard
            key={id}
            account={accounts[id]}
            accountId={id}
            allAccounts={accounts}
          />
        );
      })}
    </div>
  );
};

export default ManageAccounts;
