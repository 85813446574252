import React, { useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  makeStyles,
} from "@material-ui/core";
// import ConfigContext from "./";
import useConfig from "../hooks/useConfig";
import { keys } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  prefixLabel: {
    zIndex: 1,
    backgroundColor: "white",
  },
  prefix: {
    minWidth: "40%",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  phone: {
    minWidth: "50%",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
}));

const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const PrefixSelect = ({
  onChangePrefix,
  onChangePhoneNumber,
  onPhoneSetValidity,
  countryISOCode,
  phoneNumber,
}) => {
  useEffect(() => {
    const isPhoneAllowed = async (phoneNumber) => {
      // return Math.random() > 0.5;
      return true;
    };

    isPhoneAllowed(phoneNumber).then((isAllowed) => {
      onPhoneSetValidity && onPhoneSetValidity(isAllowed);
    });
  }, [phoneNumber]);

  const config = useConfig();
  const classes = useStyles();

  const getCountries = () => {
    const blockedCountries = keys(config.blockCountries || {});
    return config.countries.filter((c) => !blockedCountries.includes(c.code));
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        variant="outlined"
        className={classes.prefixLabel}
        id="prefix-select-helper-label"
      >
        Prefijo
      </InputLabel>

      <Select
        variant="outlined"
        labelId="prefix-select-helper-label"
        id="country-input"
        value={countryISOCode}
        onChange={onChangePrefix}
        className={classes.prefix}
      >
        {getCountries().map((c) => (
          <MenuItem key={c.code} value={c.code} prefix={c.prefix} code={c.code}>
            {c.label} {getFlagEmoji(c.code)} ({c.prefix})
          </MenuItem>
        ))}
      </Select>

      <TextField
        className={classes.phone}
        variant="outlined"
        id="phone-input"
        label="Celular/móvil"
        onChange={onChangePhoneNumber}
        value={phoneNumber} // Ensure only the phone number (without prefix) is displayed
      />
    </FormControl>
  );
};

export default PrefixSelect;
