// @ts-check
import normalizeTitle from "./normalizeTitle";

/**
 * Generate the title of a Track
 *
 * @param {import('./album').Track} track
 * @param {import('./album').Album} album
 * @return {string}
 */
export const getTrackTitle = (track, album) => {
  let title = "track sin título";
  if (!track || !album) {
    return title;
  }

  if (track.title) {
    title = track.title;
  }

  const parts = [title];

  if (album.live) {
    parts.push("En Vivo");
  } else if (album.version) {
    parts.push(album.version);
  }

  title = normalizeTitle(parts, album.lyrics_lang, album.disabledAutoTitle);

  return title;
};

export default getTrackTitle;
