import { firebase } from "../firebase";
import { get } from "lodash";

export default () => {
  const uid = get(firebase, "auth.currentUser.uid", false);

  if (!uid) {
    return false;
  }

  const agustin = "GVNSllWiNyepmhkLNw3rX8xOJpP2"; // agustin@ojiva.es
  const gerardoTornero = "AwGBrzdfvIPGzEANHv1Mc7ZHZVN2"; // gerardo.tornero@therecordingconsort.com

  const x = `${agustin}${gerardoTornero}`;
  return x.indexOf(uid) > -1;
};
