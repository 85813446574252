//@ts-check
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";

import * as routes from "../../../../../constants/routes";

import albumActions from "../../../../../reducers/albumActions";
import toastAServiceError from "../../../../../utils/toastServiceError";
import ConfirmationDialog from "./ConfirmationDialog";

/**
 * @typedef {import("../../../../../business-objects/album").Album} Album
 */

/**
 * @param {{album: Album}} props
 */
//eslint-disable-next-line
const CancelButton = ({ album }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { upc, status } = album;

  const abortEdits = async () => {
    try {
      setIsProcessing(true);
      await dispatch(albumActions.albumDiscardChanges(upc));
      dispatch({ type: "OPEN_ALBUM_FIELD", path: null });
      history.push(routes.ALBUMS);
    } catch (e) {
      toastAServiceError(e, "No se pudo descartar los cambios");
    } finally {
      setIsProcessing(false);
      setShowConfirmation(false);
    }
  };

  const title = status === "draft" ? "Eliminar álbum" : "Descartar cambios";
  const message =
    status === "draft" ? (
      <div>
        ¿Quieres cancelar la subida del álbum? Se eliminarán todos los datos,
        imágenes y audios. <strong>Este cambio es irreversible.</strong>
      </div>
    ) : (
      <div>
        Vas a eliminar las modificaciones en curso.{" "}
        <strong>Este cambio es irreversible.</strong>
      </div>
    );

  return (
    <>
      {showConfirmation && (
        <ConfirmationDialog
          okLabel={title}
          onOk={abortEdits}
          onCancel={() => setShowConfirmation(false)}
          title="Confirmar"
          message={message}
          loading={isProcessing}
        />
      )}
      <Button
        onClick={() => setShowConfirmation(true)}
        style={{ marginRight: 5 }}
      >
        Cancelar
      </Button>
    </>
  );
};

/**
 * @param {{album: Album}} props
 */
const DraftStatusButtons = ({ album, hasErrors }) => {
  return (
    <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
      <span style={{ color: "gray" }}>
        { hasErrors ? "Hay errores..." : "Guardado" }
      </span>
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={routes.ALBUM_VIEW.replace(":upc", album.upc)}
        endIcon={<ExitToApp />}
        style={{ marginRight: 10 }}
        disabled={hasErrors}
      >
        Salir 
      </Button>
    </div>
  );
};

/**
 * @param {{album: Album}} props
 */
const LiveStatusButtons = ({ album }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnEdit = async () => {
    await dispatch(albumActions.albumStartEdition(album.upc));
    history.push(routes.ALBUM_EDIT.replace(":upc", album.upc));
  };

  if (!album) {
    return;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleOnEdit}
      startIcon={<EditIcon />}
    >
      Editar
    </Button>
  );
};

/**
 *
 * @param {{album: Album}} props
 */
export const WorkflowButtons = ({ album, hasErrors }) => {
  if (!album) {
    return <></>;
  }

  const { status } = album;

  let Comp = null;

  if (status === "draft") {
    // @ts-ignore
    Comp = DraftStatusButtons;
  } else if (status === "live") {
    Comp = LiveStatusButtons;
  } 

  // @ts-ignore
  return Comp ? <Comp album={album} hasErrors={hasErrors} /> : <></>;
};

export default WorkflowButtons;
