// @ ts-check
import React from "react";
import EditableInput from "./componentes/EditableInput";
import EditableSwitch from "./componentes/EditableSwitch";
import EditableArtist from "./componentes/EditableArtist";
import EditableAutocomplete from "./componentes/EditableAutocomplete";
import EditableGenre from "./componentes/EditableGenre";
import { EditableDate } from "./componentes/EditableDate";
import EditableComposer from "./componentes/EditableComposer";

/** @typedef {import("../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

export { EditableDate, EditableSwitch, EditableInput };
/**
 *
 * @param {{label: string, name: string, type: FieldTypes, level: FieldLevelType, required?: boolean, canDelete?:boolean, disabled?:boolean}} props
 * @returns
 */
const EditableField = (props) => {
  const { type } = props;
  let Comp = null;

  if (type === "input") {
    Comp = EditableInput;
  } else if (type === "switch") {
    Comp = EditableSwitch;
  } else if (type === "artist") {
    Comp = EditableArtist; // requiere refactor esta preparado para formik
  } else if (type === "date") {
    Comp = EditableDate;
  } else if (type === "autocomplete") {
    Comp = EditableAutocomplete;
  } else if (type === "genre") {
    Comp = EditableGenre;
  } else if (type === "composer") {
    Comp = EditableComposer;
  }
  return Comp ? <Comp {...props} /> : <></>;
};

export default EditableField;
