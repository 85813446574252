// @ts-check
import { pick, omit } from "lodash";
import {
  getArtistRolesForGenre,
  makeupId,
  getAlbumFormatName,
  makeReleaseDates,
  makeCopyrightFields,
  getAlbumTitle,
  normalizeTitle,
} from "../../business-objects";

/** @typedef {import("../../business-objects/album").Album} Album */
/** @typedef {import("../../business-objects/album").ArtistRole} ArtistRole */
/** @typedef {import("../../business-objects/user").User} User */
/** @typedef {import("../../reducers/store").StateStore} StateStore */
/** @typedef {import("./wizardStore").WizardNewAlbumState} WizardNewAlbumState */
/** @typedef {import("./wizardStore").NewAlbumPayload} NewAlbumPayload*/
/** @typedef {import('./wizardStore').NewAlbumArtist} NewAlbumArtist */
/** @typedef {import('./wizardStore').PayloadToServer} PayloadToServer */
/** @typedef {import('./wizardStore').NewAlbumAlbum} NewAlbumAlbum */

/**
 * @param {WizardNewAlbumState} state
 * @param {User} user
 * @return {NewAlbumPayload}
 */
export const stateToPayload = (state, user) => {
  const tmpState = omit(state, "submiting", "artistRolesAvailables");

  if (!tmpState.tracks) tmpState.tracks = [];

  tmpState.subaccount_user = user.email;

  return tmpState;
};

/**
 * @param {NewAlbumPayload} newAlbumPayload
 * @return {WizardNewAlbumState}
 */
export const payloadToState = (newAlbumPayload) => {
  const genreSubgenre = newAlbumPayload?.album?.genreSubgenre;

  if (!genreSubgenre) {
    return {
      ...newAlbumPayload,
      artistRolesAvailables: [],
    };
  }

  return {
    ...newAlbumPayload,
    artistRolesAvailables: getArtistRolesForGenre(
      genreSubgenre?.genre,
      genreSubgenre?.subgenre
    ),
  };
};

const _getImageInfo = (image) => {
  if (!image) {
    return image;
  }

  const fields = ["extension", "mime", "name", "storageLocation", "url"];

  return pick(image, fields);
};

/**
 *
 * @param {Array<import("./actions/trackOrder").NewAlbumTrack>} tracks
 * @param {string} albumTitle
 * @returns {{ [key:string]: import("../../business-objects/album").Track}}
 */
const prepareTracksToSubmit = (tracks, albumTitle) => {
  if (!tracks) {
    return {};
  }

  const result = {};

  const isSingle = tracks.length === 1;

  tracks.forEach((track, index) => {
    result[track.isrc] = {
      ...pick(
        track,
        "extension",
        "filename",
        "originalFilename",
        "id",
        "storageLocation",
        "isrc"
      ),
      title: isSingle ? albumTitle : normalizeTitle([track.title]),
      version: "",
      explicit: false,
      order: index + 1,
    };
  });
  // @ts-ignore
  return result;
};

/**
 * @param {User} user
 * @param {NewAlbumAlbum} album
 * @return {string}
 */
const _getSubaccount = (user, album) => {
  try {
    const { firstnames, lastnames } = user;
    const { artists } = album;

    if (firstnames || lastnames) {
      return `${firstnames} ${lastnames}`.trim();
    } else if (artists[0].name) {
      return artists[0].name;
    }

    return "unknown";
  } catch (e) {
    return "unknown";
  }
};

/**
 * @param {WizardNewAlbumState} state
 * @param {User} user
 * @return {PayloadToServer}
 */
export const prepareAlbumToSubmit = (state, user) => {
  const { account, album, tracks, uploadingKey } = state;

  const uid = user.uid;
  const email = user.email;

  const { genreSubgenre } = album;

  const titleNormalized = normalizeTitle([album.title]);

  /** @type {PayloadToServer} */
  let payload = {
    ...album,
    title: titleNormalized,
    titleLabel: getAlbumTitle(album),
    format: "",
    account,
    genre: genreSubgenre?.genre || "",
    subgenre: genreSubgenre?.subgenre || "",
    photos: {
      cover: _getImageInfo(album.photos.cover),
    },
    ...makeReleaseDates(),
    tracks: prepareTracksToSubmit(tracks, titleNormalized),
    status: "draft",
    subaccount: _getSubaccount(user, album), // sello <- emails en subaccount
    subaccount_user: email,
    subaccount_user_id: uid,
    timestamps: {
      uploaded: Date.now(),
    },
    uploadingKey,
  };

  payload = {
    ...payload,
    format: getAlbumFormatName(payload),
    ...makeCopyrightFields(payload),
  };

  return payload;
};

/**
 * @param {ArtistRole} role
 * @param {boolean=} primary
 * @return {NewAlbumArtist}
 */
export const newArtist = (role, primary = false) => {
  return {
    id: makeupId(),
    name: "",
    role: role.label,
    primary,
  };
};
