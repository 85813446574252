import React from "react";
import { useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconDeleteConfirmButton from "../../../../../components-cross/IconDeleteConfirmButton";
import { AutocompleteArtists } from "../../../../../components-cross/AutocompleteArtists";

import useEditableField from "../hooks/useEditableField";
import albumActions from "../../../../../reducers/albumActions";
import useTrackOpened from "../../../../../reducers/album/hooks/useTrackOpened";

import { isObject } from "formik";

/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldEditable} FieldEditable*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldLevelType} FieldLevelType*/
/** @typedef {import("../../../../../business-objects/album.fieldEditable").FieldTypes} FieldTypes*/

/**
 * @param {{label: string, name: string, type: FieldTypes, level: FieldLevelType, required?:boolean}} props
 */
const EditableArtist = (props) => {
  const pathArtist = props.name;
  const pathArtistName = pathArtist + "/name";
  const dispatch = useDispatch();
  const track = useTrackOpened();

  const {
    upc,
    path,
    label,
    value,
    setValue,
    canDelete,
    deleteField,
    required,
    error,
  } = useEditableField({
    ...props,
    name: pathArtistName,
    debounceTimeToSave: 1000,
  });


  /**
   *
   * @param {object} event
   * @param {object | string} spotyArtist
   */
  const handleOnChange = (event, spotyArtist) => {
    const name = spotyArtist?.name || spotyArtist || "";
    setValue(name);
    if(isObject(spotyArtist)){
      saveArtistImageAndSpotyId(spotyArtist);
    }
  };

  const saveArtistImageAndSpotyId = async (spotyArtist) => {
    const images = spotyArtist?.images;
    const artistImageUrl = images ? images[images.length - 1]?.url || "" : "";
    const spotifyId = spotyArtist?.id || "";
    const popularity = spotyArtist?.popularity || null;

    let pathArtistImage, pathArtistSpotifyId, pahtArtistPopularity;

    if (props.level === "track") {
      if (!track) {
        return;
      }

      pathArtistImage = `tracks/${track.isrc}/${pathArtist}/imageUrl`;
      pathArtistSpotifyId = `tracks/${track.isrc}/${pathArtist}/spotifyId`;
      pahtArtistPopularity = `tracks/${track.isrc}/${pathArtist}/popularity`;
    } else {
      pathArtistImage = pathArtist + "/imageUrl";
      pathArtistSpotifyId = pathArtist + "/spotifyId";
      pahtArtistPopularity = pathArtist + "/popularity";
    }

    /** @type {FieldEditable} */
    const field = {
      label: "Avatar",
      path: pathArtistImage,
      level: props.level,
    };

    /** @type {FieldEditable} */
    const field2 = {
      label: "Spotify ID",
      path: pathArtistSpotifyId,
      level: props.level,
    };

    /** @type {FieldEditable} */
    const field3 = {
      label: "Spotify Popularity",
      path: pahtArtistPopularity,
      level: props.level,
    };

    await Promise.all([
      dispatch(
        albumActions.saveFieldChange(upc, field, "update", artistImageUrl, true)
      ),
      dispatch(
        albumActions.saveFieldChange(upc, field2, "update", spotifyId, true)
      ),
      dispatch(
        albumActions.saveFieldChange(upc, field3, "update", popularity, true)
      ),
    ]);
  };

  const handleOnDelete = (e) => {
    deleteField();
  };

  return (
    <AutocompleteArtists
      label={label}
      name={path}
      value={value}
      onChange={handleOnChange}
      fullWidth
      required={required}
      error={!!error}
      helperText={error}
      InputProps={
        canDelete
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconDeleteConfirmButton onClick={handleOnDelete} />
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );
};

export default EditableArtist;
