import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  useTheme,
  useMediaQuery,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import {
  COUNTRIES_DATA,
  DEFAULT_DATA,
  PAYPAL,
  BANK,
  DEFAULT_DATA_FORM,
} from "./constants";
import { PaymentSchema } from "./schema";
import { useStyles } from "./styles";
import { keys } from "lodash";
import useConfig from "../../../hooks/useConfig";
import AccountContext from "../../../contexts/AccountContext";
import { firebase } from "../../../firebase";

const PaymentForm = (props) => {
  const { uid, email } = firebase.auth.currentUser;
  const [defaultValues, setDefaultValues] = useState(DEFAULT_DATA_FORM);
  const [visibleName, setVisibleName] = useState("");
  const {
    requestPayment,
    working,
    numSelected,
    selectedAmount,
    MIN_PAYMENT,
    localMoney,
  } = props;

  const account = useContext(AccountContext);

  useEffect(() => {
    if (account) {
      const { nameLegal, nameDisplay, name } = account;
      setVisibleName(nameLegal || nameDisplay || name);
    }
  }, [account]);

  const getUserData = async (open) => {
    try {
      const snapshot = await firebase.db.ref(`/users/${uid}`).once("value");
      const { banking, firstnames, lastnames, paypal, phone } = snapshot.val();

      let dataForm = {
        ...DEFAULT_DATA_FORM,
        fullName: `${firstnames} ${lastnames}`,
        phone,
        email,
      };

      if (banking) {
        dataForm = {
          ...banking,
          email, // email must be the currentUser
        };

        const { residenceCountry } = banking;

        let taxValue = DEFAULT_DATA.tax;
        let idValue = DEFAULT_DATA.identification;
        let paymentOptionsValue = DEFAULT_DATA.payment.options;

        const countryData = COUNTRIES_DATA[residenceCountry?.code];

        if (countryData) {
          taxValue = countryData.tax;
          idValue = countryData.identification;

          if (countryData.payment) {
            paymentOptionsValue = countryData.payment.options;
            const { bankName, accountNumber, accountType, pathNumber } =
              countryData.payment.data;
            setBankNameLabel(bankName.label);
            setAccountNumberLabel(accountNumber.label);
            setAccountTypeLabel(accountType.label);
            setAccountTypeOptions(accountType.options);
            if (bankName.options) {
              setBankNameOptions(bankName.options);
            } else {
              setBankNameOptions([]);
            }
            if (pathNumber) {
              setPathNumberLabel(pathNumber.label);
            }
          }
        }

        setPaymentOptions(paymentOptionsValue);
        setPaymentValue(dataForm.paymentType);
        setTaxLabel(taxValue);
        setIdLabel(idValue);
        setCountrySelected(
          banking.residenceCountry || dataForm.residenceCountry
        );
        setPaypalEmail(banking.paypalEmail || "");
        setBirthday(new Date(banking.birthday));
      }

      setDefaultValues({ ...dataForm });

      if (open) {
        setOpen(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const config = useConfig();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [countrySelected, setCountrySelected] = useState(
    defaultValues.residenceCountry
  );
  const [taxLabel, setTaxLabel] = useState(DEFAULT_DATA.tax);
  const [idLabel, setIdLabel] = useState(DEFAULT_DATA.identification);
  const [paymentValue, setPaymentValue] = useState(PAYPAL);
  const [paymentOptions, setPaymentOptions] = useState(
    DEFAULT_DATA.payment.options
  );
  const [bankNameLabel, setBankNameLabel] = useState("");
  const [bankNameOptions, setBankNameOptions] = useState([]);
  const [pathNumberLabel, setPathNumberLabel] = useState("");
  const [accountNumberLabel, setAccountNumberLabel] = useState("");
  const [accountSwiftLabel, setAccountSwiftLabel] = useState("");
  const [accountTypeLabel, setAccountTypeLabel] = useState("");
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);
  const [accountTypeValue, setAccountTypeValue] = useState("");
  const [paypalEmail, setPaypalEmail] = useState("");
  const [birthday, setBirthday] = useState(new Date());

  const { control, handleSubmit, errors, setValue, reset } = useForm({
    mode: "onBlur",
    validationSchema: PaymentSchema,
    defaultValues,
  });

  const handleClickOpen = () => {
    getUserData(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    setDefaultValues(defaultValues);
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const handleChangeCountry = (data) => {
    let taxValue = DEFAULT_DATA.tax;
    let idValue = DEFAULT_DATA.identification;
    let paymentOptionsValue = DEFAULT_DATA.payment.options;
    let paymentOptionId = paymentOptionsValue[0].id;

    // console.log("change country", data);

    if (data?.code) {
      const countryData = COUNTRIES_DATA[data.code];

      if (countryData) {
        taxValue = countryData.tax;
        idValue = countryData.identification;
        if (countryData.payment) {
          paymentOptionsValue = countryData.payment.options;
          paymentOptionId =
            paymentOptionsValue[paymentOptionsValue.length - 1].id;
          setPaymentOptions(paymentOptionsValue);
          setPaymentValue(paymentOptionId);
          setValue("paymentType", paymentOptionId);
          const {
            bankName,
            accountNumber,
            accountType,
            pathNumber,
            accountSwift,
          } = countryData.payment.data;
          setBankNameLabel(bankName.label);
          setAccountNumberLabel(accountNumber.label);
          setAccountSwiftLabel(accountSwift.label);
          setAccountTypeLabel(accountType.label);
          setAccountTypeOptions(accountType.options);
          setAccountTypeValue(accountType.options[0]);
          setValue("accountType", accountType.options[0]);
          if (bankName.options) {
            setBankNameOptions(bankName.options);
            setValue("bankName", bankName.options[0]);
          } else {
            setBankNameOptions([]);
            setValue("bankName", "");
          }
          if (pathNumber) {
            setPathNumberLabel(pathNumber.label);
          }
        }
      }
    } else {
      setPaymentOptions(paymentOptionsValue);
      setValue("paymentType", paymentOptionId);
      setPaymentValue(paymentOptionId);
      setBankNameOptions([]);
      setValue("bankName", "");
      setAccountTypeOptions([]);
      setAccountTypeValue(null);
      setValue("accountType", null);
    }

    setTaxLabel(taxValue);
    setIdLabel(idValue);
  };

  useEffect(() => {
    setCountrySelected(countrySelected);
  }, [countrySelected]);
  useEffect(() => {
    setBankNameOptions(bankNameOptions);
  }, [bankNameOptions]);
  useEffect(() => {
    setAccountTypeOptions(accountTypeOptions);
  }, [accountTypeOptions]);
  useEffect(() => {
    setAccountTypeValue(accountTypeValue);
  }, [accountTypeValue]);

  useEffect(() => {
    setPaymentValue(paymentValue);
  }, [paymentValue]);

  const onSubmit = async (data) => {
    delete data.agreement;
    delete data.agreement2;
    const _data = { ...data, birthday: data.birthday.toString() };
    await firebase.db.ref(`/users/${uid}/banking`).set(_data);
    handleClose();
    requestPayment();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={working || numSelected === 0 || selectedAmount < MIN_PAYMENT}
        aria-label="Payment"
      >
        Solicitar pago de {localMoney.format(selectedAmount)}
      </Button>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
      >
        <DialogTitle id="customized-dialog-title">
          Datos de facturación
        </DialogTitle>

        <DialogContent>
          {keys(errors).length > 0 && (
            <p className={classes.inputError}>
              Corrige los errores y vuelve a intentarlo...
            </p>
          )}

          <Controller
            as={<TextField />}
            disabled={true}
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            required
            name="email"
            control={control}
            defaultValue=""
          />
          {errors.email && (
            <p className={classes.inputError}>{errors.email.message}</p>
          )}

          <Controller
            as={<TextField />}
            margin="dense"
            label="Nombre completo"
            type="text"
            fullWidth
            helperText="IMPORTANTE: Utiliza tu nombre real para que podamos procesar la petición."
            required
            name="fullName"
            control={control}
            defaultValue=""
          />
          {errors.fullName && (
            <p className={classes.inputError}>{errors.fullName.message}</p>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                as={<Autocomplete />}
                options={config.countries}
                getOptionLabel={(option) => option.label}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.label, inputValue);
                  const parts = parse(option.label, matches);
                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    fullWidth
                    required
                    {...params}
                    label="Pais de residencia"
                  />
                )}
                getOptionSelected={(option, { code }) => {
                  if (!code) {
                    return false;
                  }
                  return option.code === code;
                }}
                onChange={([, data]) => {
                  setCountrySelected(data);
                  handleChangeCountry(data);
                  return data;
                }}
                name="residenceCountry"
                control={control}
                defaultValue={null}
              />
              {errors.residenceCountry && (
                <p className={classes.inputError}>
                  {errors.residenceCountry.message}
                </p>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={<Autocomplete />}
                options={config.countries}
                getOptionLabel={(option) => option.label}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.label, inputValue);
                  const parts = parse(option.label, matches);
                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    fullWidth
                    required
                    {...params}
                    label="Nacionalidad"
                  />
                )}
                getOptionSelected={(option, { code }) => {
                  return option.code === code;
                }}
                margin="dense"
                onChange={([, data]) => data}
                name="nationality"
                control={control}
                defaultValue={null}
              />
              {errors.nationality && (
                <p className={classes.inputError}>
                  {errors.nationality.message}
                </p>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                as={<TextField />}
                margin="dense"
                label={taxLabel}
                type="text"
                fullWidth
                required
                name="taxIdentification"
                control={control}
                defaultValue=""
              />
              {errors.taxIdentification && (
                <p className={classes.inputError}>
                  {errors.taxIdentification.message}
                </p>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={<TextField />}
                margin="dense"
                label={idLabel}
                type="text"
                fullWidth
                required
                name="identification"
                control={control}
                defaultValue=""
              />
              {errors.identification && (
                <p className={classes.inputError}>
                  {errors.identification.message}
                </p>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                as={<DatePicker />}
                autoOk
                animateYearScrolling
                format="dd/MM/yyyy"
                invalidDateMessage="Fecha no válida"
                invalidLabel=""
                label="Fecha de nacimiento"
                required
                fullWidth
                clearable
                name="birthday"
                control={control}
                defaultValue={birthday}
              />
              {errors.birthday && (
                <p className={classes.inputError}>{errors.birthday.message}</p>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={<TextField />}
                label="Profesión"
                type="text"
                fullWidth
                required
                name="profession"
                control={control}
                defaultValue=""
              />
              {errors.profession && (
                <p className={classes.inputError}>
                  {errors.profession.message}
                </p>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                as={<TextField />}
                margin="dense"
                label="Codigo postal"
                type="text"
                fullWidth
                required
                name="postalCode"
                control={control}
                defaultValue=""
              />
              {errors.postalCode && (
                <p className={classes.inputError}>
                  {errors.postalCode.message}
                </p>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={<TextField />}
                margin="dense"
                label="Número de teléfono móvil"
                type="number"
                fullWidth
                required
                name="phone"
                control={control}
                defaultValue=""
              />
              {errors.phone && (
                <p className={classes.inputError}>{errors.phone.message}</p>
              )}
            </Grid>
          </Grid>
          <Controller
            as={<TextField />}
            margin="dense"
            label="Ciudad"
            type="text"
            fullWidth
            required
            name="city"
            control={control}
            defaultValue=""
          />
          {errors.city && (
            <p className={classes.inputError}>{errors.city.message}</p>
          )}
          <Controller
            as={<TextField />}
            margin="dense"
            label="Dirección completa"
            fullWidth
            multiline
            minRows={2}
            required
            name="address"
            control={control}
            defaultValue=""
          />
          {errors.address && (
            <p className={classes.inputError}>{errors.address.message}</p>
          )}
          <Controller
            as={
              <TextField>
                {paymentOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
            margin="dense"
            select
            label="Método de pago"
            onChange={([e]) => {
              setPaymentValue(e.target.value);
              return e.target.value;
            }}
            type="text"
            fullWidth
            required
            name="paymentType"
            control={control}
            value={paymentValue}
            defaultValue={paymentOptions[0].id}
          />

          {paymentValue !== BANK && (
            <Controller
              as={<TextField />}
              margin="dense"
              label="Paypal email"
              type="email"
              fullWidth
              required
              name="paypalEmail"
              control={control}
              defaultValue={paypalEmail}
            />
          )}

          {errors.paypalEmail && (
            <p className={classes.inputError}>{errors.paypalEmail.message}</p>
          )}

          {paymentValue === BANK && (
            <Controller
              as={
                (bankNameOptions.length === 0 && <TextField />) ||
                (bankNameOptions.length > 0 && (
                  <TextField>
                    {bankNameOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                ))
              }
              margin="dense"
              select={bankNameOptions.length > 0}
              label={bankNameLabel}
              onChange={([e]) => {
                return e.target.value;
              }}
              type="text"
              fullWidth
              required
              name="bankName"
              control={control}
              defaultValue={
                bankNameOptions.length > 0 ? bankNameOptions[0] : ""
              }
            />
          )}
          {errors.bankName && (
            <p className={classes.inputError}>{errors.bankName.message}</p>
          )}
          {paymentValue === BANK &&
            countrySelected &&
            countrySelected.code === "US" && (
              <Controller
                as={<TextField />}
                margin="dense"
                label={pathNumberLabel}
                type="text"
                fullWidth
                required
                name="pathNumber"
                control={control}
                defaultValue=""
              />
            )}
          {errors.pathNumber && (
            <p className={classes.inputError}>{errors.pathNumber.message}</p>
          )}
          {paymentValue === BANK && (
            <Controller
              as={<TextField />}
              margin="dense"
              label={accountNumberLabel}
              type="text"
              fullWidth
              required
              name="accountNumber"
              control={control}
              defaultValue=""
            />
          )}
          {errors.accountNumber && (
            <p className={classes.inputError}>{errors.accountNumber.message}</p>
          )}
          {paymentValue === BANK && (
            <Controller
              as={<TextField />}
              margin="dense"
              label={accountSwiftLabel}
              type="text"
              fullWidth
              required
              name="accountSwift"
              control={control}
              defaultValue=""
            />
          )}
          {errors.accountSwift && (
            <p className={classes.inputError}>{errors.accountSwift.message}</p>
          )}
          {paymentValue === BANK && (
            <Controller
              as={
                <TextField>
                  {accountTypeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              }
              margin="dense"
              select
              label={accountTypeLabel}
              onChange={([e]) => {
                setAccountTypeValue(e.target.value);
                return e.target.value;
              }}
              type="text"
              fullWidth
              required
              name="accountType"
              control={control}
              defaultValue={accountTypeOptions[0]}
            />
          )}
          <FormControlLabel
            className={classes.formControl}
            control={
              <Controller
                as={Checkbox}
                name="agreement"
                type="checkbox"
                control={control}
                color="primary"
                margin="dense"
                defaultValue={false}
              />
            }
            label="Al seleccionar esta casilla, confirmo que todos los datos que estoy incluyendo en este formulario son veraces."
          />
          {errors.agreement && (
            <p className={classes.inputError}>{errors.agreement.message}</p>
          )}

          <FormControlLabel
            className={classes.formControl}
            control={
              <Controller
                as={Checkbox}
                name="agreement2"
                type="checkbox"
                control={control}
                color="primary"
                margin="dense"
                defaultValue={false}
              />
            }
            label={`Al seleccionar esta casilla acepto el Acuerdo de Autofacturación de ${visibleName} y/o cualquiera de sus entidades colaboradoras, y autorizo que se utilicen los datos que estoy indicando en este acto, para emitir las factura correspondientes a los ingresos que genere mi música a través de sus servicios.`}
          />
          <Box className={classes.contract}>
            <a target="_blank" href="/acuerdo-autofacturacion">
              Acuerdo de Autofacturación
            </a>
          </Box>
          {errors.agreement2 && (
            <p className={classes.inputError}>{errors.agreement2.message}</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            Solicitar pago
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PaymentForm.propTypes = {
  requestPayment: PropTypes.func.isRequired,
  working: PropTypes.bool.isRequired,
  numSelected: PropTypes.number.isRequired,
  selectedAmount: PropTypes.number.isRequired,
  MIN_PAYMENT: PropTypes.number.isRequired,
  localMoney: PropTypes.object.isRequired,
};

export { PaymentForm };
