import { useState, useEffect, useMemo } from "react";
import { firebase } from "../firebase";
import AuthUserContext from "./AuthUserContext";
import AccountContext from "../contexts/AccountContext";
import { getAccount, getAccountBySubdomain } from "../firebase/db";
import { keys } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress"; // Import CircularProgress from v4
import { makeStyles } from "@material-ui/core/styles"; // Import makeStyles for styling
import { doSignOut } from "../firebase/auth";

const getFullUser = firebase.functions.httpsCallable("getFullUser");

// Create styles for centering the loader
const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Full viewport height
  },
}));

const withAuthentication = (Component) => {
  return function WithAuthenticationWrapper(props) {
    const classes = useStyles(); // Use the styles
    const [authUser, setAuthUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const handleAuthStateChange = async (firebaseUser) => {
        setLoading(true);
        setError(null);

        if (firebaseUser) {
          await handleAuthenticatedUser(firebaseUser);
        } else {
          await handleAnonymousUser();
        }

        setLoading(false);
      };

      const unsubscribe = firebase.auth.onAuthStateChanged(
        handleAuthStateChange
      );

      return () => {
        unsubscribe && unsubscribe();
      };
    }, []);

    const handleAuthenticatedUser = async (firebaseUser) => {
      try {
        const { data: fullUser } = await getFullUser(firebaseUser.uid);
        const authUserAccount = fullUser.accountFull;

        const _user = fullUser;

        if (!fullUser) {
          throw new Error("User not found");
        }
        const subdomainAccount = await getAccountBySubdomain();

        const isMember = keys(subdomainAccount.members).includes(_user.uid);
        const isOwner = subdomainAccount.owner === _user.uid;
        const isNotFromThisDomain = authUserAccount.id !== subdomainAccount.id;

        // console.log("Is member:", isMember);
        // console.log("Is owner:", isOwner);
        // console.log("Is not from this domain:", isNotFromThisDomain);

        // reject user if not a member or owner
        if (!isMember && !isOwner && isNotFromThisDomain) {
          doSignOut();
        } else {
          setAccount(subdomainAccount);
        }

        setAuthUser(_user);
      } catch (error) {
        console.log("Error fetching full user data:", error);
        setError("Failed to fetch user data. Please try again later.");
      }
    };

    const handleAnonymousUser = async () => {
      setAuthUser(null);

      try {
        const anonymousAccount = await getAccountBySubdomain();

        setAccount(anonymousAccount);
      } catch (error) {
        console.error("Error fetching anonymous account:", error);
        setError("Error. Por favor inténtalo de nuevo más tarde.");
      }
    };

    const authUserValue = useMemo(() => authUser, [authUser]);
    const accountValue = useMemo(() => account, [account]);

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <AuthUserContext.Provider value={authUserValue}>
        <AccountContext.Provider value={accountValue}>
          <Component {...props} />
        </AccountContext.Provider>
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthentication;
