// @ts-check
import normalizeTitle from "./normalizeTitle";
import getArtistsFeats from "./getArtistsFeats";

/**
 * @typedef {import('./album').Album} Album
 * @typedef {import('./album').Artist} Artist
 * @typedef {import('../reducers/wizardNewAlbum/wizardStore').NewAlbumAlbum} NewAlbumAlbum
 * @typedef {import('../reducers/wizardNewAlbum/wizardStore').NewAlbumArtist} NewAlbumArtist
 */

/**
 * Generate the complete title for an Album (with feats, live, version)
 * @param {{
 *  artists: {[key:string]: Artist} | Array<NewAlbumArtist>
 *  title: string,
 *  live: boolean,
 *  lyrics_lang: string,
 *  disabledAutoTitle?:boolean,
 *  version?:string }} album
 * @return {string}
 */
export const getAlbumTitle = (album) => {
  if (!album?.title || typeof album.title !== "string") {
    return "";
  }

  /** @type {string} */
  let title = album.title;

  const parts = [title];

  const feats = getArtistsFeats(album.artists);
  if (feats.length > 0) {
    parts.push("feat. " + feats.map((feat) => feat.name).join(", "));
  }

  if (album.live) {
    parts.push("En Vivo");
  } else if (album.version) {
    parts.push(album.version);
  }

  title = normalizeTitle(parts, album.lyrics_lang, album.disabledAutoTitle);

  return title;
};

export default getAlbumTitle;
