// @ts-check
import { isArray } from "lodash";
// import isPrimaryArtist from "./isPrimaryArtist";
import { values } from "lodash";

/** @typedef {import("./album").Artist} Artist */
/** @typedef {import("./album").ArtistRole} ArtistRole */
/** @typedef {import("../reducers/wizardNewAlbum/wizardStore").NewAlbumArtist} NewAlbumArtist */

const _getPrincipalesFromArray = (artists) => {
  return artists.filter((artist) => {
    return artist.role.toLowerCase().includes("principal");
  });
};

/**
 * Search and return the main primary artit
 *
 * @param {{[key:string]: Artist} | Array<NewAlbumArtist>} artists
 * @return {Array<Artist>}
 */
export const getArtistsMain = (artists) => {
  if (!artists) {
    return [];
  }

  return isArray(artists)
    ? _getPrincipalesFromArray(artists)
    : _getPrincipalesFromArray(Object.values(artists));
};

export default getArtistsMain;
