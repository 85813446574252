import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { Bar } from "react-chartjs-2";
import { orderBy, keys } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  autocomplete: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  sortGroup: {
    // Queda a la izquierda
  },
  paginationGroup: {
    display: "flex",
    alignItems: "center",
  },
  monthsGroup: {
    marginLeft: theme.spacing(2),
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    tooltip: {
      callbacks: {
        // title: function ([{ dataset, dataIndex }]) {
        //   console.log(dataset.data[dataIndex]);
        //   return `${dataset.data[dataIndex]} reproducciones fraudulentas`;
        // },
        label: function (context) {
          if (context.datasetIndex === 0)
            return `${context.formattedValue} ${context.dataset.label}`;
          else return `${context.formattedValue} escuchas fraudulentas`;
        },
      },
    },
  },
  stacked: false,
  scales: {
    x: {
      display: true,
      title: {
        text: "Período",
      },
    },
    y: {
      title: {
        display: true,
        text: "Reproducciones",
      },
    },
    y1: {
      title: {
        display: true,
        text: "Repr. fraud. (% del total permitido)",
      },
      min: 0,
      max: 100,
      position: "right",
    },
  },
};

const FraudByTrack = ({ data }) => {
  const classes = useStyles();
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [streamsByTrackAndPeriod, setStreamsByTrackAndPeriod] = useState([]);
  const [fraudulentByPeriod, setFraudulentByPeriod] = useState({});
  const [fraudulentByTrackAndPeriod, setFraudulentByTrackAndPeriod] = useState({});
  const [fullChartData, setFullChartData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [tooltips, setTooltips] = useState([]);

  // Estado de paginación
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [totPages, setTotPages] = useState(0);

  // Estado de ordenamiento: "alphabetic" o "fraudulent"
  const [sortOrder, setSortOrder] = useState("fraudulent");

  useEffect(() => {
    if (data) {
      // tracks: almacenar objetos completos para el Autocomplete
      setTracks(data.fraudulentTracks);

      // seleccionar el primer track (si existe)
      if (data.fraudulentTracks.length > 0) {
        setSelectedTrack(data.fraudulentTracks[0]);
      }

      // streamsByTrackAndPeriod
      setStreamsByTrackAndPeriod(data.streamsByTrackAndPeriod);

      // fraudulentByTrackAndPeriod
      setFraudulentByTrackAndPeriod(data.fraudulentByTrackAndPeriod);

      // fraudulentByPeriod
      setFraudulentByPeriod(data.fraudulentByPeriod);
    }
  }, [data]);

  // Construir los datos completos para el gráfico (antes de paginar)
  useEffect(() => {
    if (
      selectedTrack &&
      streamsByTrackAndPeriod &&
      fraudulentByTrackAndPeriod
    ) {
      // Filtrar usando selectedTrack.isrc
      const trackStreamsByPeriodArr = streamsByTrackAndPeriod.filter(
        (item) => item.isrc === selectedTrack.isrc
      );
      
      const trackStreamsByPeriodObj = trackStreamsByPeriodArr.reduce(
        (acc, curr) => {
          acc[curr.period] = curr.cantidad;
          return acc;
        },
        {}
      );

      // const trackFraudulentByPeriodArr = fraudulentByTrackAndPeriod.filter(
      //   (item) => item.isrc === selectedTrack.isrc
      // );

      const fullChartData = {
        labels: trackStreamsByPeriodArr.map((item) => item.periodHuman),
        datasets: [
          {
            label: "Reproducciones",
            data: trackStreamsByPeriodArr.map((track) => track.cantidad),
            backgroundColor: "rgba(0, 120, 0, 1)",
            yAxisID: "y",
          },
          {
            label: "Reproducciones fraudulentas del período",
            // data: trackFraudulentByPeriodArr.map((x) => {
            //   const totGood = trackStreamsByPeriodObj[x.period];
            //   const maxBad = totGood / 100; // 1% del total es el máximo permitido
            //   return Math.round(x.cantidad / maxBad);
            //   return x.cantidad
            // }),
            data: keys(fraudulentByTrackAndPeriod).map((period) => fraudulentByTrackAndPeriod[period][selectedTrack.isrc]),
            backgroundColor: "rgba(250, 0, 0, 1)",
            yAxisID: "y1",
          },
        ],
      };

      setFullChartData(fullChartData);
      setPage(1); // Reiniciar a la primera página cuando cambia el track
    }
  }, [selectedTrack, streamsByTrackAndPeriod, fraudulentByTrackAndPeriod]);

  // Aplicar paginación a los datos completos del gráfico
  useEffect(() => {
    if (fullChartData && fullChartData.labels) {
      const labels = fullChartData.labels;
      const numPages = Math.ceil(labels.length / pageSize);
      setTotPages(numPages);

      // Función de paginación: la página 1 muestra los últimos pageSize elementos, etc.
      const paginate = (arr) => {
        const start = page * pageSize * -1;
        const end = arr.length - pageSize * (page - 1);
        return arr.slice(start, end);
      };

      const paginatedLabels = paginate(labels);
      const paginatedDatasets = fullChartData.datasets.map((dataset) => ({
        ...dataset,
        data: paginate(dataset.data),
      }));

      setChartData({
        labels: paginatedLabels,
        datasets: paginatedDatasets,
      });
    }
  }, [fullChartData, page, pageSize]);

  // Ordenar los tracks para el Autocomplete según el orden seleccionado
  const sortedTracks =
    sortOrder === "alphabetic"
      ? orderBy(tracks, ["title"], ["asc"])
      : orderBy(tracks, ["cantidad"], ["desc"]);

  // Handlers de paginación
  const nextPage = () => {
    if (page < totPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const changePageSize = (size) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Reproducciones fraudulentas por track
      </Typography>

      {/* Autocomplete para seleccionar el track */}
      <div className={classes.autocomplete}>
        <Autocomplete
          options={sortedTracks}
          getOptionLabel={(option) => option.title}
          value={selectedTrack}
          onChange={(event, newValue) => setSelectedTrack(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Elige track" variant="outlined" />
          )}
        />
      </div>

      {/* Controles: grupo de ordenamiento a la izquierda y de paginación a la derecha */}
      <div className={classes.controls}>
        <div className={classes.sortGroup}>
          <ButtonGroup color="primary" size="small">
            <Button
              variant="contained"
              onClick={() => setSortOrder("fraudulent")}
              disabled={sortOrder === "fraudulent"}
            >
              Más fraudulentos primero
            </Button>
            <Button
              variant="contained"
              onClick={() => setSortOrder("alphabetic")}
              disabled={sortOrder === "alphabetic"}
            >
              Órden alfabético
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.paginationGroup}>
          <ButtonGroup color="primary" size="small">
            <Button
              variant="contained"
              disabled={page >= totPages}
              onClick={nextPage}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="contained"
              disabled={page <= 1}
              onClick={prevPage}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>
          <div className={classes.monthsGroup}>
            <ButtonGroup color="primary" size="small">
              <Button
                variant="contained"
                disabled={pageSize === 3}
                onClick={() => changePageSize(3)}
              >
                3 meses
              </Button>
              <Button
                variant="contained"
                disabled={pageSize === 12}
                onClick={() => changePageSize(12)}
              >
                12 meses
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>

      {/* Gráfico */}
      {chartData && <Bar data={chartData} options={chartOptions} />}
    </div>
  );
};

export default FraudByTrack;
