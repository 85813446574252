import * as React from "react";
import Box from "@material-ui/core/Box";
import Avatar from "./Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Icon from "@material-ui/core/Icon";
import Help from "@material-ui/icons/Help";
import { auth } from "../firebase";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountSwitchDialog from "./AccountSwitchDialog";
import * as routes from "../constants/routes";
import AccountLinkDialog from "./AccountLinkDialog";
import PersonAdd from "@material-ui/icons/PersonAdd";

export default function UserAccountMenu({ distributor, user, history }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [switchUserAccountDialogOpen, setSwitchUserAccountDialogOpen] =
    React.useState(false);
  const [userAccountLinkDialogOpen, setUserAccountLinkDialogOpen] =
    React.useState(false);
  const [linkedUserAccounts, setLinkedUserAccounts] = React.useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoUserAccount = () => {
    history.push(routes.ACCOUNT);
    handleClose();
  };

  const switchUserAccount = () => {
    setSwitchUserAccountDialogOpen(true);
    handleClose();
  };

  const openUserAccountLink = () => {
    setUserAccountLinkDialogOpen(true);
    handleClose();
  };

  if (!user) return null;

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "user-account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar user={user} />
        </IconButton>
        <MoreVertIcon onClick={handleClick} color="inherit" fontSize="small" />
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="user-account-menu"
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={gotoUserAccount}>{user.email}</MenuItem>

        <Divider />

        <MenuItem onClick={switchUserAccount}>
          <ListItemIcon>
            <SupervisorAccountIcon fontSize="small" />
          </ListItemIcon>
          Cambiar de usuario
        </MenuItem>

        <MenuItem onClick={openUserAccountLink}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Añadir usuario
        </MenuItem>

        {distributor === "Subidalibre" ? (
          <MenuItem
            onClick={() => window.open("https://subidalibre.tawk.help/")}
          >
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            Ayuda
          </MenuItem>
        ) : null}

        <MenuItem onClick={auth.doSignOut}>
          <ListItemIcon>
            <Icon>power_settings_new</Icon>
          </ListItemIcon>
          Salir
        </MenuItem>
      </Menu>

      <AccountSwitchDialog
        open={switchUserAccountDialogOpen}
        closeDialog={() => setSwitchUserAccountDialogOpen(false)}
        openAccountLink={openUserAccountLink}
        setLinkedAccounts={setLinkedUserAccounts}
        linkedAccounts={linkedUserAccounts}
        user={user}
      />
      <AccountLinkDialog
        open={userAccountLinkDialogOpen}
        closeDialog={() => setUserAccountLinkDialogOpen(false)}
        linkedAccounts={linkedUserAccounts}
      />
    </React.Fragment>
  );
}
