import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { fbroot } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";

// Firebase Realtime Database reference
const db = fbroot.database();

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1100,
    margin: "auto",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(5),
      // paddingLeft: theme.spacing(5),
    },
  },
  formGrid: {
    marginBottom: theme.spacing(2),
  },
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccountEditForm = () => {
  const { accountId } = useParams();
  const [initialValues, setInitialValues] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    const accountRef = db.ref(`accounts/${accountId}`);
    accountRef.once("value").then((snapshot) => {
      if (snapshot.exists()) {
        setInitialValues(snapshot.val());
      } else {
        setInitialValues({
          name: "",
          nameDisplay: "",
          subdomain: "",
          tarifa: "",
          helpSiteDomain: "",
          owner: "",
          owner_email: "",
          supra: "",
        });
      }
    });
  }, [accountId]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    nameDisplay: Yup.string(),
    subdomain: Yup.string().required("Required").default("TARIFA_72"),
    tarifa: Yup.string().required("Required"),
    helpSiteDomain: Yup.string().required("Required"),
    owner: Yup.string().required("Required"),
    owner_email: Yup.string().email("Invalid email").required("Required"),
    supra: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const accountRef = db.ref(`accounts/${accountId}`);
    accountRef.update(values).then(() => {
      setSubmitting(false);
      alert("Account updated successfully!");
    });
  };

  if (!initialValues) return <CircularProgress />;

  return (
    <Paper className={classes.root}>
      <Container maxWidth="md">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, touched, isSubmitting, values, handleChange }) => (
            <Form>
              <Grid container spacing={3} className={classes.formGrid}>
                <Grid item xs={12}>
                  <div className={classes.titleBar}>
                    <Typography variant="h4" gutterBottom>
                      {initialValues.name}
                    </Typography>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Guardando..." : "Guardar"}
                    </Button>
                  </div>
                </Grid>

                {/* Name */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                {/* Display Name */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="nameDisplay"
                    label="Display Name"
                    fullWidth
                    variant="outlined"
                    error={touched.nameDisplay && !!errors.nameDisplay}
                    helperText={touched.nameDisplay && errors.nameDisplay}
                  />
                </Grid>

                {/* Subdomain */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="subdomain"
                    label="Subdomain"
                    fullWidth
                    variant="outlined"
                    error={touched.subdomain && !!errors.subdomain}
                    helperText={touched.subdomain && errors.subdomain}
                  />
                </Grid>

                {/* Tarifa (Select) */}
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    name="tarifa"
                    label="Tarifa"
                    fullWidth
                    variant="outlined"
                    value={values.tarifa}
                    onChange={handleChange}
                    error={touched.tarifa && !!errors.tarifa}
                    helperText={touched.tarifa && errors.tarifa}
                  >
                    <MenuItem value="TARIFA_72">Tarifa 72</MenuItem>
                    <MenuItem value="TARIFA_50">Tarifa 50</MenuItem>
                    <MenuItem value="TARIFA_30">Tarifa 30</MenuItem>
                  </TextField>
                </Grid>

                {/* Help Site Domain */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="helpSiteDomain"
                    label="Help Site Domain"
                    fullWidth
                    variant="outlined"
                    error={touched.helpSiteDomain && !!errors.helpSiteDomain}
                    helperText={touched.helpSiteDomain && errors.helpSiteDomain}
                  />
                </Grid>

                {/* Owner ID */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="owner"
                    label="Owner ID"
                    fullWidth
                    variant="outlined"
                    error={touched.owner && !!errors.owner}
                    helperText={touched.owner && errors.owner}
                  />
                </Grid>

                {/* Owner Email */}
                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="owner_email"
                    label="Owner Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    error={touched.owner_email && !!errors.owner_email}
                    helperText={touched.owner_email && errors.owner_email}
                  />
                </Grid>

                {/* Supra (Select) */}
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    name="supra"
                    label="Supra"
                    fullWidth
                    variant="outlined"
                    value={values.supra}
                    onChange={handleChange}
                    error={touched.supra && !!errors.supra}
                    helperText={touched.supra && errors.supra}
                  >
                    <MenuItem value="-LnP0Lzh9ezSrtdfvMZr">Option 1</MenuItem>
                    <MenuItem value="Option_2">Option 2</MenuItem>
                    <MenuItem value="Option_3">Option 3</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Paper>
  );
};

export default AccountEditForm;
